import * as React from "react";

export const ProjectCommissionTeamStructureIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#F8C6A5"
      d="M15.5 23.961a1.914 1.914 0 1 0 0-3.828 1.914 1.914 0 0 0 0 3.828"
    ></path>
    <path
      fill="#A0A3F2"
      d="M19.094 29h-7.188v-.586A3.584 3.584 0 0 1 15.5 24.82a3.584 3.584 0 0 1 3.594 3.594z"
    ></path>
    <path
      fill="#F8C6A5"
      d="M8.469 22.047c0 1.055-.86 1.914-1.914 1.914a1.917 1.917 0 0 1-1.914-1.914c0-1.055.859-1.914 1.914-1.914s1.914.86 1.914 1.914"
    ></path>
    <path
      fill="#A0A3F2"
      d="M10.148 29H3v-.586a3.584 3.584 0 0 1 3.594-3.594 3.584 3.584 0 0 1 3.593 3.594V29z"
    ></path>
    <path
      fill="#F8C6A5"
      d="M22.531 22.047c0 1.055.86 1.914 1.914 1.914 1.055 0 1.914-.86 1.914-1.914 0-1.055-.859-1.914-1.914-1.914s-1.914.86-1.914 1.914"
    ></path>
    <path
      fill="#A0A3F2"
      d="M20.852 29H28v-.586a3.584 3.584 0 0 0-3.594-3.594 3.584 3.584 0 0 0-3.593 3.594V29z"
    ></path>
    <path
      fill="#353535"
      d="M6.867 22.047c0 .742-.43 1.406-1.094 1.719.235.117.508.195.82.195 1.055 0 1.915-.86 1.915-1.914 0-1.055-.86-1.914-1.914-1.914-.313 0-.586.078-.82.195.624.313 1.093.977 1.093 1.719M6.594 24.82c-.274 0-.547.04-.82.079 1.601.351 2.773 1.796 2.773 3.476V29h1.64v-.586c-.039-1.992-1.64-3.594-3.593-3.594"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M4.68 27.945c-.117 0-.196-.078-.196-.195v-.47c0-.116.079-.234.196-.234s.195.079.195.196v.468c0 .118-.078.235-.195.235"
    ></path>
    <path
      fill="#353535"
      d="M15.773 22.047c0 .742-.43 1.406-1.093 1.719.234.117.508.195.82.195 1.055 0 1.914-.86 1.914-1.914 0-1.055-.86-1.914-1.914-1.914-.312 0-.586.078-.82.195a1.9 1.9 0 0 1 1.093 1.719M15.5 24.82c-.273 0-.547.04-.82.079 1.601.351 2.773 1.796 2.773 3.476V29h1.64v-.586A3.584 3.584 0 0 0 15.5 24.82"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M13.586 27.946c-.117 0-.195-.078-.195-.195v-.469c0-.117.078-.195.195-.195s.195.078.195.195v.469c0 .078-.078.195-.195.195"
    ></path>
    <path
      fill="#F8C6A5"
      d="M17.414 5.914c0 1.055-.86 1.914-1.914 1.914a1.917 1.917 0 0 1-1.914-1.914c0-1.055.86-1.914 1.914-1.914 1.055 0 1.914.86 1.914 1.914"
    ></path>
    <path
      fill="#FB695A"
      d="M19.094 12.867h-7.188v-.586A3.584 3.584 0 0 1 15.5 8.687a3.584 3.584 0 0 1 3.594 3.594z"
    ></path>
    <path
      fill="#353535"
      d="M15.773 5.914c0 .742-.43 1.406-1.093 1.719.234.117.508.195.82.195 1.055 0 1.914-.86 1.914-1.914C17.414 4.859 16.554 4 15.5 4c-.312 0-.586.078-.82.195a1.86 1.86 0 0 1 1.093 1.719M15.5 8.687c-.273 0-.547.04-.82.079 1.601.351 2.773 1.796 2.773 3.476v.586h1.64v-.586c0-1.953-1.6-3.555-3.593-3.555"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M13.586 11.773c-.117 0-.195-.079-.195-.196v-.468c0-.118.078-.196.195-.196s.195.078.195.196v.468c0 .117-.078.196-.195.196"
    ></path>
    <path
      fill="#353535"
      d="M24.68 22.047c0 .742-.43 1.406-1.094 1.719.234.117.508.195.82.195 1.055 0 1.914-.86 1.914-1.914 0-1.055-.86-1.914-1.914-1.914-.312 0-.586.078-.82.195a1.9 1.9 0 0 1 1.094 1.719M24.406 24.82c-.273 0-.547.04-.82.079 1.602.351 2.773 1.796 2.773 3.476V29H28v-.586a3.584 3.584 0 0 0-3.594-3.594"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M22.531 27.946c-.117 0-.195-.078-.195-.195v-.469c0-.117.078-.195.195-.195s.196.078.196.195v.469c0 .078-.118.195-.196.195"
    ></path>
    <path
      fill="#A44446"
      d="M24.406 16.89H6.594c-.235 0-.39-.156-.39-.39s.155-.39.39-.39h17.851c.235 0 .39.155.39.39 0 .234-.194.39-.429.39"
    ></path>
    <path
      fill="#A44446"
      d="M15.5 17.983c-.234 0-.39-.156-.39-.39v-2.188c0-.234.156-.39.39-.39s.39.155.39.39v2.187c0 .235-.156.391-.39.391M24.406 18.687c-.234 0-.39-.156-.39-.39V16.5c0-.235.156-.39.39-.39s.39.155.39.39v1.797c0 .234-.155.39-.39.39M6.594 18.687c-.235 0-.39-.156-.39-.39V16.5c0-.235.155-.39.39-.39.234 0 .39.155.39.39v1.797c0 .234-.195.39-.39.39"
    ></path>
  </svg>
);