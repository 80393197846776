import { RouteObject } from 'react-router'
import { lazy } from 'react'

const PurchaserIDType = lazy(() => import('./PurchaserIDType'))

export const TEAM_ASSIGNMENT_PATH = {
  TEAM_ASSIGNMENT_LISTING: '/purchaser-id-type',
}

const purchaserIDRoutes: RouteObject[] = [
  {
    path: 'purchaser-id-type',
    children: [
      {
        path: '',
        element: <PurchaserIDType />,
      },
    ],
  },
]

export default purchaserIDRoutes

