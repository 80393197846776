import {lazy} from 'react'
import {RouteObject} from 'react-router'


const JobPositionListing = lazy(() =>
    import(`./JobPositionList/JobPositionListing`))

const EmployeeJobPositionListing = lazy(() => 
import(`./EmployeeJobPositionList/EmployeeJobPositionListing`)
)

const EmployeeJobPositionRoutes: RouteObject[] = [
    {
        path: 'employee-job-position',
        children: [
            {
                path: 'job-position',
                element: <JobPositionListing />
            },
            {
                path: 'list',
                element: <EmployeeJobPositionListing />
            }
        ]
    }
]

export default EmployeeJobPositionRoutes