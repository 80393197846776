import { lazy } from "react";
import { RouteObject } from "react-router";

const UnitOfMeasurementListing = lazy(() => 
    import(`./UnitOfMeasurementListing`).then(module => ({
        default: module.UnitOfMeasurementListing,
    }))
)

const UnitOfMeasurementRoutes: RouteObject[] = [
    {
        path: 'unit-of-measurement',
        children: [
            {
                path: '',
                element: <UnitOfMeasurementListing />
            }
        ]
    }
]

export default UnitOfMeasurementRoutes