import { Card, Container, Footer, MenuItem } from '@ifca-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { ICommisisonItemFormSchema } from './Form/type'
import { PayoutType, TargetBy } from './Form/type'
import { yupResolver } from '@hookform/resolvers/yup'
import { commisisonItemFormSchema } from './Form/schema'
import { TextField } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import AppContext, { AppContextProps } from 'containers/context/Context'
import useNav from 'components/RouteService/useNav'
import { useSubmitCommissionTypeMutation } from 'generated/graphql'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'

interface ICommissionItemFormProps {
  mode: 'new' | 'edit'
}

const defaultDataForm: ICommisisonItemFormSchema = {
  name: '',
  payOutType: PayoutType.FIXED_AMOUNT,
  targetBy: TargetBy.NET_SELLING_PRICE,
  interval: 1,
}

const payOutOptions = Object.values(PayoutType).map((payOutOption) => ({
  value: payOutOption,
  label: payOutOption
}))
const targetByOptions = Object.values(TargetBy).map((targetByOption) => ({
  value: targetByOption,
  label: targetByOption
}))

const CommissionTypeForm = ({ mode }: ICommissionItemFormProps) => {
  const {rootState, rootDispatch} = useContext<AppContextProps>(AppContext);
  const params = useParams();
  const { navBack } = useNav();
  const { state } = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ICommisisonItemFormSchema>({
    // defaultValues: defaultDataForm,
    defaultValues: {
      name: state.name ?? '',
      payOutType: state.payOutType ?? PayoutType.FIXED_AMOUNT,
      targetBy: state.targetBy ?? TargetBy.NET_SELLING_PRICE,
      interval: state.interval ?? 1,
    },
    mode: 'onSubmit',
    resolver: yupResolver(commisisonItemFormSchema),
  })

  const [submitCommissionType] = useSubmitCommissionTypeMutation({
    onCompleted(data) {
      if(data.submitCommissionType){
        setSnackBar(
          mode === "new" ? SystemMsgs.createNewRecord() : SystemMsgs.updateRecord()
        );
        navBack()
      }
    },
  })

  // USE EFFECT
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'close',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {},
        topSection: {
          smTitle: 'Sales Commission',
          title: state?.projectName ?? localStorage.getItem('projectName'),
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Commission Type',
          },
          rightText: mode[0].toUpperCase() + mode.slice(1, mode.length),
        },
      },
    })
  }, [params.projectID])

  // FUNCTIONS
  const onSubmit = (data: ICommisisonItemFormSchema) => {
    submitCommissionType({
      variables: {
        input: {
          id: mode === 'edit' ? state.id : undefined,
          name: data.name,
          interval: Number(data.interval),
          payOutType: data.payOutType, 
          targetBy: data.targetBy
        }
      }
    })
  }

  return (
    <form>
      <Container>
        <Card>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                label="Commission Type Name"
                autoComplete="off"
                error={!!errors?.name}
                fullWidth
                variant="standard"
                helperText={errors?.name?.message}
                sx={{
                  marginBottom: '15px',
                }}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="interval"
            render={({ field }) => (
              <TextField
                {...field}
                label="Calc. Interval In Month(s)"
                autoComplete="off"
                error={!!errors?.interval}
                fullWidth
                variant="standard"
                type="number"
                helperText={errors?.interval?.message}
                sx={{
                  marginBottom: '15px',
                }}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="payOutType"
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Commission Pay Out Type"
                autoComplete="false"
                error={!!errors?.payOutType}
                helperText={errors?.payOutType?.message}
                required
                fullWidth
                variant="standard"
                sx={{
                  marginBottom: '15px',
                }}
              >
                {payOutOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="targetBy"
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Target By"
                autoComplete="false"
                error={!!errors?.targetBy}
                helperText={errors?.targetBy?.message}
                required
                fullWidth
                variant="standard"
                sx={{
                  marginBottom: '15px',
                }}
              >
                {targetByOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
            )}
          />
        </Card>
      </Container>
      <Footer
        buttons={[
          {
            children: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(onSubmit)()
            },
            type: 'button',
          },
        ]}
      />
    </form>
  )
}

export default CommissionTypeForm
