import * as React from "react";

export const ProjectCommissionPackageTargetIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FD4755"
      fillRule="evenodd"
      d="M17.294 3.203c5.564 1.118 9.168 6.535 8.05 12.098-1.119 5.564-6.535 9.167-12.098 8.05-5.564-1.118-9.168-6.535-8.05-12.098s6.534-9.167 12.098-8.05"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F2FBFF"
      fillRule="evenodd"
      d="M16.856 5.39a8.044 8.044 0 0 1 6.3 9.472 8.043 8.043 0 1 1-15.771-3.17 8.044 8.044 0 0 1 9.47-6.301"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD4755"
      fillRule="evenodd"
      d="M16.376 7.775a5.612 5.612 0 1 1-2.212 11.005 5.612 5.612 0 0 1 2.212-11.005"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F2FBFF"
      fillRule="evenodd"
      d="M15.95 9.893a3.45 3.45 0 1 1-1.361 6.765 3.45 3.45 0 0 1 1.361-6.765"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FD4755"
      fillRule="evenodd"
      d="M15.574 11.77a1.539 1.539 0 1 1-.608 3.016 1.539 1.539 0 0 1 .608-3.017"
      clipRule="evenodd"
    ></path>
    <path
      fill="#E41F2D"
      fillRule="evenodd"
      d="M17.295 3.203c5.564 1.118 9.167 6.535 8.05 12.098-1.007 5.003-5.489 8.42-10.427 8.246 4.644-.167 8.74-3.49 9.697-8.246 1.117-5.563-2.487-10.98-8.05-12.098a10.5 10.5 0 0 0-1.672-.196c.789-.029 1.593.034 2.402.196"
      clipRule="evenodd"
    ></path>
    <path
      fill="#E8F0FE"
      fillRule="evenodd"
      d="M16.855 5.391a8.044 8.044 0 0 1 6.3 9.47 8.04 8.04 0 0 1-8.255 6.452 8.045 8.045 0 0 0-.006-16.07q.968-.047 1.96.148"
      clipRule="evenodd"
    ></path>
    <path
      fill="#E41F2D"
      fillRule="evenodd"
      d="M16.376 7.775a5.612 5.612 0 0 1-1.459 11.103 5.613 5.613 0 0 0 .742-11.103 6 6 0 0 0-.754-.1c.482-.03.976 0 1.471.1"
      clipRule="evenodd"
    ></path>
    <path
      fill="#E8F0FE"
      fillRule="evenodd"
      d="M15.95 9.893a3.45 3.45 0 0 1-1.133 6.804 3.451 3.451 0 0 0-.006-6.841q.557-.077 1.14.037"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFD15B"
      fillRule="evenodd"
      d="m9.507 13.143 3.253 1.877 3.254 1.878v7.516L12.76 26.29 9.507 28.17l-3.254-1.88L3 24.415v-7.516l3.253-1.878z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFC328"
      fillRule="evenodd"
      d="m9.507 13.143 3.253 1.877 3.254 1.878v7.516L12.76 26.29 9.507 28.17z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F7A31B"
      fillRule="evenodd"
      d="m9.507 13.143 3.253 1.877 3.254 1.878-3.254 1.88-3.253 1.878-3.254-1.879L3 16.898l3.253-1.878z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#31A7FB"
      fillRule="evenodd"
      d="m7.147 19.293 3.253-1.879 3.254-1.878-.706-.407-1.315-.76-3.254 1.88-3.253 1.876v3.41l.93-.786 1.09 1.98z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFD54F"
      d="M15.27 13.643a.365.365 0 0 1-.199-.672L24.72 6.7a.365.365 0 0 1 .398.613l-9.649 6.272a.36.36 0 0 1-.198.059"
    ></path>
    <path
      fill="#31A7FB"
      fillRule="evenodd"
      d="m28.969 6.125-1.226.904-1.274.939-1.156-.234-1.156-.233.117-1.174.118-1.174 1.274-.94 1.226-.904-.118 1.175-.119 1.173 1.158.234z"
      clipRule="evenodd"
    ></path>
  </svg>
);