import { Container, List, ListItem, ListItemText, Spinner } from '@ifca-ui/core'
import { KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  ExpandMore as ExpandMoreIcon
 } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, IconButton, ListItemIcon, Typography } from '@mui/material'
import useNav from 'components/RouteService/useNav'
import { SwitchProjectDialog } from 'components/SwitchProjectDialog/SwitchProjectDialog'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useGetProjectNameByIdQuery } from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/formatDate'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useGetAllJobPositionQuery } from 'generated/graphql'
import { EmployeeJobPositionIcon } from 'components/Icon/EmployeeJobPositionIcon'
import { CommissionTypeIcon } from 'components/Icon/CommissionTypeIcon'
import { CompanyRewardIcon } from 'components/Icon/CompanyRewardIcon'
import { ProjectCommissionIcon } from 'components/Icon/ProjectCommissionIcon'
import { ProjectCommissionTeamStructureIcon } from 'components/Icon/ProjectCommissionTeamStructureIcon'
import { ProjectCommissionTeamStructureIcon as CompanyCommissionTeamStructureIcon } from 'components/Icon/ProjectCommissionTeamStructureIcon'
import { ProjectCommissionPackageTargetIcon } from 'components/Icon/ProjectCommissionPackageTargetIcon'
import { ProjectCommissionPackageTargetIcon as CompanyCommissionPackageTargetIcon} from 'components/Icon/ProjectCommissionPackageTargetIcon'
import { CommissionCalculationIcon } from 'components/Icon/CommissionCalculationIcon'
import { DigitalReportIcon } from 'components/Icon/DigitalReportIcon'

type TCommissionMenu = {
  path: string
  icon: JSX.Element
  moduleName: string
  moduleInfo: string | React.ReactNode
}

export const baseSalesCommissionPath: string = '/sales-commission'

const SalesComissionSubMenu = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { navTo } = useNav()
  const { projectID } = useParams()
  const [isOpenSwitchProjectDialog, setIsOpenSwitchProjectDialog] =
    useState<boolean>(false)
  const [activeProject, setActiveProject] = useState<string>(projectID)
  const [expanded, setExpanded] = useState<string>(
    sessionStorage.getItem('employeeJobPosExpanded') || ""
  )
  const [projectName, setProjectName] = useState<string>(
    localStorage.getItem('projectName')
  )
  const [totalJobPosotion, setTotalJobPostion] =useState(0)

  const {
          loading: getJobPositionLoading,
          refetch: refetchList,
        } = useGetAllJobPositionQuery({
          variables: {
            projectId: projectID,
          },
          onCompleted: data => {
            setTotalJobPostion(data.getAllJobPosition.length)
          }
        })

  const employeeJobPositionSubMenuList = [
    {
      url: `${baseSalesCommissionPath}/${projectID}/employee-job-position/job-position`,
      module: 'Job Position',
      subInfo: (
        <Typography
          component="span"
          variant="inherit"
          className="text-icon text-desc"
        >
          <span>
            No. of Records: &nbsp; 
            <span className="search-bar-count-f">
              {totalJobPosotion}
            </span>
          </span>
        </Typography>
      )
    },
    {
      url: `${baseSalesCommissionPath}/${projectID}/employee-job-position/list`,
      module: 'Employee Job Position',
      subInfo: (
        <Typography
          component="span"
          variant="inherit"
          className="text-icon text-desc"
        >
          <span>
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
          </span>
        </Typography>
      )
    }
  ]
  

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if(isExpanded) {
      sessionStorage.setItem('employeeJobPosExpanded', panel)
      sessionStorage.setItem('employeeJobPosScrollPos', window.scrollY + "" )
      setExpanded(panel)
    } else {
      sessionStorage.removeItem('employeeJobPosExpanded')
      sessionStorage.removeItem('employeeJobPosScrollPos')
      setExpanded("") 
    }
   }

  const salesComissionMenus: TCommissionMenu[] = [
    {
      path: `${baseSalesCommissionPath}/${projectID}/employee-job-position`,
      icon: (
        <EmployeeJobPositionIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleInfo: "Configure Employee Job Position",
      moduleName: "Employee Job Position"
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/commission-type`,
      icon: (
        <CommissionTypeIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Commission Type',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          No. of Records : <strong style={{color: '#ff9800'}}>3</strong>
        </Typography>
      ),
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/company-reward`,
      icon: (
        <CompanyRewardIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Company Reward',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          No. of Records : <strong style={{color: '#ff9800'}}>3</strong>
        </Typography>
      ),
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/project-commission`,
      icon: (
        <ProjectCommissionIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Project Commission',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
        </Typography>
      ),
    },
    {       
      path: `${baseSalesCommissionPath}/${projectID}/company-commission-team-structure`,
      icon: (
        <CompanyCommissionTeamStructureIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Company Commission Team Structure',
      moduleInfo:(
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
        </Typography>
      )
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/project-commission-team-structure`,
      icon: (
        <ProjectCommissionTeamStructureIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Project Commission Team Structure',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
        </Typography>
      ),
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/company-commission-package-target`,
      icon: (
        <CompanyCommissionPackageTargetIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Company Commission Package and Target',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
        </Typography>
      ),
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/project-commission-package-target`,
      icon: (
        <ProjectCommissionPackageTargetIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Project Commission Package and Target',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          Last updated on{' '}
          <strong>{formatDate(new Date().toISOString())}</strong>
        </Typography>
      ),
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/commission-calculation`,
      icon: (
        <CommissionCalculationIcon
          className="svg-icon"
          height="30px"
          width="30px"
        />
      ),
      moduleName: 'Commission Calculation',
      moduleInfo: '-',
    },
    {
      path: `${baseSalesCommissionPath}/${projectID}/digital-report`,
      icon: (
        <DigitalReportIcon className="svg-icon" height="30px" width="30px" />
      ),
      moduleName: 'Digital Reports',
      moduleInfo: (
        <Typography
          className="text-desc"
          color="#454545"
          component="div"
          variant="inherit"
        >
          <strong>20</strong> Reports
        </Typography>
      ),
    },
  ]

  const { loading: getProjectNameByIdLoading } = useGetProjectNameByIdQuery({
    variables: {
      projectId: projectID,
    },
    onCompleted: data => {
      setProjectName(data.getProjectById.name)
      localStorage.setItem('projectName', data.getProjectById.name)
    },
  })

  const onCloseSwitchProjectDialogHandler = (): void => {
    setIsOpenSwitchProjectDialog(false)
  }

  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'menu',
          props: {
            onClick: () =>
              rootDispatch({
                type: 'isDrawerOpen',
                payload: !rootState.isDrawerOpen,
              }),
          },
        },
        rightIcon: {
          icon: 'switch',
          props: {
            onClick: () => {
              setIsOpenSwitchProjectDialog(true)
            },
          },
        },
        topSection: {
          smTitle: 'Sales Commission',
          title: projectName,
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 1,
            current: 'Main Menu',
          },
        },
      },
    })
  }, [projectName])

  return (
    <>
      {getProjectNameByIdLoading && <Spinner />}
      <Container>
        <List>
          {salesComissionMenus.map(menu =>
          menu?.moduleName == 'Employee Job Position' ? (
            <div key={menu.moduleName} style={{marginBottom: '5px'}}>
              <Accordion
              expanded={expanded === 'Employee Job Position'}
              onChange={handleAccordionChange('Employee Job Position')}
              >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{color: '#212121'}} />}
                sx={{padding: '0 16px 0 12px', height: '64px'}}
                >
                  <div style={{marginRight: '-20px', display: 'flex', alignItems: 'center'}}>
                    <ListItemIcon>{menu.icon}</ListItemIcon>
                  </div>
                  <ListItemText
                  primary={
                    <Typography
                    color="common.black"
                    component="div"
                    display="flex"
                    variant="inherit"
                    >
                      <Typography
                      className='text-xsTitle'
                      component='span'
                      flex="1"
                      variant='inherit'
                      >
                        {menu.moduleName}
                      </Typography>
                    </Typography>
                  }
                  secondary={
                    <Typography>
                      {typeof menu.moduleInfo !== 'string' ? (
                        menu.moduleInfo
                      ) : (
                        <Typography
                        className='text-desc'
                        color='#454545'
                        component='div'
                        variant='inherit'
                        >
                          {menu.moduleInfo}
                        </Typography>
                      )}
                    </Typography>
                  }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {employeeJobPositionSubMenuList.map(subMenu => (
                      <ListItem
                      key={subMenu.module}
                      secondaryAction={
                        <IconButton onClick={() => navTo(subMenu.url)}>
                          <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                      }
                      onClick={() => navTo(subMenu.url)}
                      >
                        <ListItemText
                        primary={
                          <Typography
                          color='common.black'
                          component='div'
                          display='flex'
                          variant='inherit'
                          >
                            <Typography
                            className='text-xsTitle'
                            component='span'
                            flex='1'
                            variant='inherit'
                            >
                              {subMenu.module}
                            </Typography>
                          </Typography>
                        }
                        secondary={
                          <Typography
                          component='div'
                          >
                            <Typography
                            className='text-desc'
                            color='#454545'
                            component='div'
                            variant='inherit'
                            >
                              {subMenu.subInfo}
                            </Typography>
                          </Typography>
                        }
                        />

                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            
            </div>
          ) : (
            <ListItem
              key={menu.moduleName}
              secondaryAction={
                <IconButton>
                  <KeyboardArrowRightOutlinedIcon />
                </IconButton>
              }
              onClick={() => navTo(menu.path, {
                projectName
              })}
            >
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color="common.black"
                    component="div"
                    display="flex"
                    variant="inherit"
                    marginBottom="3px"
                  >
                    <Typography
                      className="text-xsTitle"
                      component="span"
                      flex="1"
                      variant="inherit"
                    >
                      {menu.moduleName}
                    </Typography>
                  </Typography>
                }
                secondary={
                  <Typography component="div">
                    {typeof menu.moduleInfo !== 'string' ? (
                      menu.moduleInfo
                    ) : (
                      <Typography
                        className="text-desc"
                        color="#454545"
                        component="div"
                        variant="inherit"
                      >
                        {menu.moduleInfo}
                      </Typography>
                    )}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <SwitchProjectDialog
          open={isOpenSwitchProjectDialog}
          onClose={onCloseSwitchProjectDialogHandler}
          name="Project"
          projectId={activeProject}
          setProjectId={setActiveProject}
          page="SalesCommission"
        />
      </Container>
    </>
  )
}

export default SalesComissionSubMenu
