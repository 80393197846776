import * as React from "react";

export const CompanyRewardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <g clipPath="url(#clip0_13196_104032)">
      <path
        fill="#FDB800"
        d="m11.575 19.697 5.252-10.173-2.576-1.328-6.058 11.746a.267.267 0 0 0 .299.383l2.646-.624a.25.25 0 0 1 .244.067c.073.03.157-.001.193-.072"
      ></path>
      <path
        fill="#FFDA2D"
        d="m16.699 9.458-5.317 10.31q.042.038.064.092l1.027 2.518c.086.21.38.224.484.021l6.058-11.746z"
      ></path>
      <path
        fill="#FDB800"
        d="m15.52 9.345-2.537 1.308 6.058 11.746c.105.203.399.19.485-.021l1.026-2.518a.26.26 0 0 1 .234-.165l.044-.054z"
      ></path>
      <path
        fill="#FFDA2D"
        d="M23.805 19.942 17.747 8.196l-2.284 1.178 5.323 10.321q.038-.001.074.006l2.646.624a.267.267 0 0 0 .299-.383"
      ></path>
      <path
        fill="#FD4755"
        d="M17.267.607a1.63 1.63 0 0 0 1.855.497 1.627 1.627 0 0 1 2.195 1.267c.109.7.658 1.25 1.358 1.358a1.627 1.627 0 0 1 1.267 2.195c-.256.661-.055 1.411.497 1.855a1.627 1.627 0 0 1 0 2.535 1.63 1.63 0 0 0-.497 1.855 1.627 1.627 0 0 1-1.267 2.195c-.7.109-1.25.658-1.358 1.358a1.627 1.627 0 0 1-2.195 1.268 1.63 1.63 0 0 0-1.855.497 1.627 1.627 0 0 1-2.535 0 1.63 1.63 0 0 0-1.855-.497 1.627 1.627 0 0 1-2.195-1.268 1.63 1.63 0 0 0-1.358-1.358 1.627 1.627 0 0 1-1.267-2.195c.256-.66.055-1.41-.497-1.855a1.627 1.627 0 0 1 0-2.535 1.63 1.63 0 0 0 .497-1.855A1.627 1.627 0 0 1 9.324 3.73c.7-.108 1.25-.658 1.358-1.358a1.627 1.627 0 0 1 2.195-1.267c.66.256 1.411.055 1.855-.497a1.627 1.627 0 0 1 2.535 0"
      ></path>
      <path
        fill="#FBE77B"
        d="M16 15.148a6.101 6.101 0 1 0 0-12.203 6.101 6.101 0 0 0 0 12.203"
      ></path>
      <path
        fill="#FFD064"
        d="M16 2.945c-.693 0-1.358.116-1.979.328a6.104 6.104 0 0 1 0 11.547A6.101 6.101 0 1 0 16 2.945"
      ></path>
      <path
        fill="#FDB800"
        d="m16.119 10.99 1.94 1.283c.167.11.38-.046.327-.237l-.62-2.243a.22.22 0 0 1 .073-.226l1.82-1.45a.215.215 0 0 0-.124-.383l-2.325-.103a.22.22 0 0 1-.192-.14l-.816-2.178a.215.215 0 0 0-.403 0l-.817 2.179a.22.22 0 0 1-.192.14l-2.324.102a.215.215 0 0 0-.125.384l1.82 1.45a.22.22 0 0 1 .074.225l-.62 2.243a.215.215 0 0 0 .325.237l1.942-1.283a.22.22 0 0 1 .237 0"
      ></path>
      <path
        fill="#FFECE3"
        d="m17.83 24.92-4.947-.57c-1.141-.161-1.712-.543-2.706-1.125A8.87 8.87 0 0 0 5.7 22.012H3.927v3.458l6.465 2.407c.55.204 1.13.309 1.716.309l7.738-.188-.01-.203.01-.773a2.36 2.36 0 0 0-2.017-2.102"
      ></path>
      <path
        fill="#FFDDCE"
        d="M27.959 24.219s-7.994 3.576-8.123 3.576l-7.727.246a4.9 4.9 0 0 1-1.717-.31l-6.465-2.406v3.96l6.465 2.406c.55.204 1.13.309 1.716.309h6.664a4.9 4.9 0 0 0 2.633-.764l8.343-4.707c.55-.489.652-1.31.238-1.919-.453-.666-1.202-.781-2.027-.391"
      ></path>
      <path
        fill="#64B5F6"
        d="M4.91 30.266c0 .47-.382.851-.852.851H2.61a.85.85 0 0 1-.851-.851v-9.237c0-.47.381-.852.851-.852h1.447c.47 0 .851.382.851.852z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_13196_104032">
        <path fill="#fff" d="M0 0h32v32H0z"></path>
      </clipPath>
    </defs>
  </svg>
);