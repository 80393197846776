
export interface ICommisisonItemFormSchema {
  name: string
  payOutType: string
  targetBy: string
  interval: number
}

export enum PayoutType  {
  FIXED_AMOUNT = 'Fixed Amount',
  PERCENTAGE_ON_NET_SELLING_PRICE = 'Percentage on Net Selling Price'
}

export enum TargetBy {
  NET_SELLING_PRICE = 'Net Selling Price',
  NO_OF_UNIT = 'No. of Unit'
}
