import { Dialog, SubheaderText, Subheader, } from "@ifca-ui/core";
import { DatePickerX } from 'components/Inputs/DatePickerX';
import { Box, Typography } from "@mui/material";
import { useForm} from "react-hook-form";
import { useAddCompanyCommissionTeamMutation } from "generated/graphql";
import { setSnackBar } from "helpers/snackBarSubjectHelper";
import { SystemMsgs } from "helpers/Messages/SystemMsg";
interface FormDialogProps {
    show: boolean;
    onClose: () => void;
    onCompleted: () => void;
}

const FormDialog = ({show, onClose, onCompleted}: FormDialogProps)=>{
  const {
    control, 
    handleSubmit, 
    reset, 
    setValue, 
    setError,
    formState: {errors},
    getValues
  } = useForm({
    defaultValues:{
      effectiveDate:'',
    }
  })

  const [addCompanyCommissionTeam] = useAddCompanyCommissionTeamMutation({onCompleted(data) {
    if(data.addCompanyCommissionTeam){
      setSnackBar(SystemMsgs.createNewRecord());
      onCompleted()
    }
  },})

  const onSubmit = (data)=> {
    if(!data.effectiveDate) return setError('effectiveDate', {message: 'Effective Date is Required'})
    addCompanyCommissionTeam({
      variables: {
        generateDate: new Date(data.effectiveDate)
      }
    })
  }

  return (
    <Dialog fullWidth={true} open={show} onClose={onClose}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="#FF9800"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    Company Commission Structure
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="#000"
                    component="span"
                    flex="1 1"
                    justifyContent={"flex-end"}
                    display={"flex"}
                    variant="inherit"
                  >
                    New
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Box>
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle"
                    color="common.black"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color="common.black"
                    component="span"
                    variant="inherit"
                  >
                    
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
        }
      body={
        <Box width="100%">
          <DatePickerX
            control={control}
            name="effectiveDate"
            label="Effective Date"
            required={true}
            helperText={errors.effectiveDate?.message}
            error={!!errors.effectiveDate}
          >
          </DatePickerX>
        </Box>
      }
      footer={{
        buttons: [
          {
            children: 'Cancel',
            color: 'primary',
            onClick: () => {
              onClose()
            },
          },
          {
            children: 'Save',
            color: 'primary',
            onClick: () =>{
              handleSubmit(onSubmit)()
            } 
          },
        ],
      }}
    />
  )
}

export default FormDialog;