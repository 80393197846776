import { Dialog, Subheader, SubheaderText, useMenuOption } from '@ifca-ui/core'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useDeleteCompanyCommissionTeamHeaderMutation, useDeleteCompanyCommissionTeamSubheaderMutation } from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { useParams } from 'react-router'

export const DeleteDialog = ({ show, data, onClose, onCompleted }) => {
  const { projectID } = useParams()

  const [deleteCompanyCommissionTeamHeader] = useDeleteCompanyCommissionTeamHeaderMutation({onCompleted(data, clientOptions) {
    if(data.deleteCompanyCommissionTeamHeader) {
      setSnackBar(SystemMsgs.deleteRecord());
      onCompleted()
    }
  }})
  const [deleteCompanyCommissionTeamSubheader] = useDeleteCompanyCommissionTeamSubheaderMutation({onCompleted(data, clientOptions) {
    if(data.deleteCompanyCommissionTeamSubheader) {
      setSnackBar(SystemMsgs.deleteRecord());
      onCompleted()
    }
  }})

  const onDelete = ()=> {
    const menuType = data?.type;
          
    switch (menuType) {
      case 'Company Commission Team Header':
        deleteCompanyCommissionTeamHeader({
          variables: {
            id: data.id
          }
        })
        break;

      case 'Company Commission Team Subheader':
        deleteCompanyCommissionTeamSubheader({
          variables: {
            id: data.id
          }
        })
        break;
  
      default:
        return;
    }
  }

  return (
    <Dialog
      fullWidth={true}
      open={show}
      onClose={onClose}
      header={
        <Subheader sx={{ padding: '10px 12px' }}>
          <SubheaderText
            primary={
              <Box borderBottom="1px solid #fff">
                <Box display="flex" margin="2px 0px" width="100%">
                  <Typography
                    className="text-xsTitle dialog-title-color"
                    component="span"
                    flex="1 1"
                    variant="inherit"
                  >
                    {data.type}
                  </Typography>
                  <Typography
                    className="text-xsTitle"
                    color='red'
                    component="span"
                    variant="inherit"
                  >
                    Delete
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Subheader>
      }
      body={
        <Box display="block" width="100%" paddingTop='8px' >
          <Typography
            className="text-xsTitle "
            variant="inherit"
          >
            Are you sure want to Delete this {data.type} ?
          </Typography>
        </Box>}
      footer={{
        buttons: 
          [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: onClose,
            },
            {
              children: 'Delete',
              color: 'primary',
              onClick: () => {
                onDelete()
              },
            },
          ],
      }}
    />
  )
}
