import * as React from "react";

export const CommissionTypeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#1494EB"
      d="M14.646 7.333v4.334a2.977 2.977 0 0 1-2.979 2.979H7.334a2.98 2.98 0 0 1-2.817-2.015 2.9 2.9 0 0 1-.162-.964V7.333a2.977 2.977 0 0 1 2.979-2.979h4.333a2.9 2.9 0 0 1 .964.163 2.98 2.98 0 0 1 2.015 2.816"
    ></path>
    <path
      fill="#FFCB03"
      d="M27.646 9.5a5.144 5.144 0 0 1-9.338 2.98 5.1 5.1 0 0 1-.953-2.98A5.15 5.15 0 0 1 22.5 4.354 5.15 5.15 0 0 1 27.646 9.5"
    ></path>
    <path
      fill="#46BCFC"
      d="M12.631 4.517a9.75 9.75 0 0 1-8.114 8.114 2.9 2.9 0 0 1-.162-.964V7.333a2.977 2.977 0 0 1 2.979-2.979h4.333a2.9 2.9 0 0 1 .964.163"
    ></path>
    <path
      fill="#FFD869"
      d="M25.48 5.307a9.8 9.8 0 0 1-7.172 7.172 5.1 5.1 0 0 1-.953-2.979A5.15 5.15 0 0 1 22.5 4.354a5.1 5.1 0 0 1 2.98.953"
    ></path>
    <path
      fill="#1494EB"
      d="M27.646 20.333v4.334a2.98 2.98 0 0 1-2.979 2.979h-4.333a2.977 2.977 0 0 1-2.98-2.98v-4.333a2.977 2.977 0 0 1 2.98-2.979h4.333c.252 0 .504.032.747.098a2.966 2.966 0 0 1 2.232 2.881M14.646 20.333v4.334a2.98 2.98 0 0 1-2.979 2.979H7.334a2.977 2.977 0 0 1-2.98-2.98v-4.333a2.977 2.977 0 0 1 2.98-2.979h4.333q.38 0 .748.098a2.966 2.966 0 0 1 2.231 2.881"
    ></path>
    <path
      fill="#46BCFC"
      d="M12.415 17.452a9.75 9.75 0 0 1-8.06 7.117v-4.236a2.977 2.977 0 0 1 2.979-2.979h4.333q.38 0 .748.098M25.415 17.452a9.75 9.75 0 0 1-8.06 7.117v-4.236a2.977 2.977 0 0 1 2.979-2.979h4.333c.252 0 .504.032.747.098"
    ></path>
  </svg>
);