import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const NotificationSetup = lazy(() => import('./NotificationSetup'))

export const NOTIFICATION_SETUP_PATH = {
    NOTIFICATION_SETUP: '/notification-setup',
}

const NotificationSetupRoutes: RouteObject[] = [
    {
        path: '',
        element: <NotificationSetup />,
    },
]

export default NotificationSetupRoutes
