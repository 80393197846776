import { FooterButtonContainer, FooterFixedRoot } from '@ifca-ui/core/src/components/Footer/Footer';
import AppContext, { AppContextProps } from 'containers/context/Context';
import {tabs} from "./CommissionCalculationListing.json";
import { useState , useEffect, useContext} from "react";
import useNav from 'components/RouteService/useNav';
import { 
    SubContainerX,
    FooterButton,
    Container,
    Card,
    Tabs,
    Tab,
} from "@ifca-ui/core"
import { IconButton, Typography, Box , TextField} from '@mui/material';
import { DatePickerX } from 'components/Inputs/DatePickerX';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommonYupValidation } from 'helpers/yup';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

export const CommissionCalculationDetail = () => {
    const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
    const [tabIndex, setTabIndex] = useState(0)
    const { navBack, navTo} = useNav()
    const location = useLocation()
    const { projectID, data } = location.state.state||{};
    interface CommissionCalculationProps {
        id: number
        date: string
        description: string
      }
      const memberSchema = yup.object().shape({
        date: CommonYupValidation.requireField(SystemMsgs.date())
      })
    const {
        handleSubmit: handleSubmit,
        register: register,
        control: control,
        setValue: setValue,
        getValues: getValues,
        watch: watch,
        reset: reset,
        clearErrors: clearErrors,
        setError: setError,
        formState: { errors:errors, isSubmitted:isSubmitted },
      } = useForm<CommissionCalculationProps>({
        defaultValues: {
          id: null,
          date: data.date??"",
          description: data.description??"",
        },
        mode: 'onSubmit',
        resolver: yupResolver(memberSchema),
      })
    useEffect(()=>{
        rootDispatch({
            type: 'headerComponent',
            payload: {
                ...rootState.headerComponent,
                leftIcon: {
                    icon: 'back',
                    props: {
                        onClick: () => navBack(),
                    },
                },
                bottomSection: {
                    breadcrumbs: {
                        current: `Commission Calculation`,
                        maxItems: 1,
                    },
                },
            }
        })
    },[])
    return (
        <>
            <SubContainerX>
                <Box display="flex">
                    <Box width={'100%'}>
                        <Tabs
                            className="bill-item-tabs"
                            value={tabIndex}
                            onChange={(e, v) => setTabIndex(v)}
                            variant="fullWidth"
                            centered
                            style={{ width: '100%' }}
                        >
                            {tabs.map((tab, i) => (
                                <Tab
                                    key={i}
                                    value={i}
                                    style={ { flexGrow: 1, maxWidth: '100%'}}
                                    label={
                                    <Typography className="text-mdLabel">{tab.label}</Typography>
                                    }
                                />
                            ))}    
                        </Tabs>
                    </Box>
                </Box>
            </SubContainerX>
            <Container>
                <Card >
                    <Box sx={{width:1, display:"block"}}>
                    <DatePickerX
                        control={control}
                        name="date"
                        label="Launch Date"
                        required
                        helperText={errors?.date?.message}
                        error={!!errors?.date}
                    />
                    <Controller
                        control={control}
                        name="description"
                        render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                            // select
                            label="Item Description"
                            autoComplete="off"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            fullWidth
                            margin="normal"
                            variant="standard"
                            required
                            error={errors?.date ? true : false}
                            helperText={errors?.date?.message}
                        >
                            {/* {ProjectCommissionList.map((option) => (
                            <MenuItem key={option.key}  value={option.value}>
                                {option.value}
                            </MenuItem>
                            ))} */}
                        </TextField>
                        )}
                    />
                    </Box>
                </Card>
            </Container>
            <FooterFixedRoot width={1} display={"flex"} justifyContent={"center"} gap={'10%'}>
                <FooterButtonContainer>
                    <FooterButton 
                    color='primary' 
                    variant="contained" 
                    sx={{
                        margin: '27px 18px 13px 9px',
                        alignItems:"bottom",
                        padding:"10px 100px"
                    }}
                    onClick={()=>navBack()}>
                        Cancel
                    </FooterButton>
                </FooterButtonContainer>
                <FooterButtonContainer>
                    <FooterButton 
                    color='primary' 
                    variant="contained" 
                    sx={{
                        margin: '27px 18px 13px 9px',
                        alignItems:"bottom",
                        padding:"10px 100px"
                    }}
                    onClick={()=>navBack()}>
                        Confirm
                    </FooterButton>

                </FooterButtonContainer>
            </FooterFixedRoot>
        </>
    )
}