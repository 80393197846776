import {
  Container,
  Dialog,
  EmptyState,
  List,
  ListItem,
  SearchBar,
  Spinner,
  Subheader,
  SubContainerX,
  SubheaderText,
} from '@ifca-ui/core'
import { Box, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  useGetAllProjectLazyQuery,
  useGetAllProjectQuery,
  useUserAccessEntityMutation,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import useNav from 'components/RouteService/useNav'

interface switchProjectDialogProps {
  open: boolean
  onClose: any
  projectId?: string
  setProjectId?: any
  page: string
  name: string
}

export const SwitchProjectDialog = ({
  open,
  onClose,
  projectId,
  setProjectId,
  page,
  name,
}: switchProjectDialogProps) => {
  let navigate = useNavigate()
  const { navBack, navTo } = useNav()

  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const [search, setSearchInput] = React.useState('')

  ////////// Get Project //////////
  //#region graphQL
  // const {
  //   data: { getAllProject } = {
  //     getAllProject: [],
  //   },
  // } = useGetAllProjectQuery({
  //   onCompleted: data => {
  //     if (!projectId) {
  //       if (setProjectId) {
  //         setProjectId(data?.getAllProject[0]?.id)
  //       }
  //     }
  //   },
  // })

  const [
    fetchProject,
    {
      loading: projectLoading,
      error: projectError,
      data: { getAllProject } = { getAllProject: [] },
    },
  ] = useGetAllProjectLazyQuery({
    onCompleted: data => {
      if (data.getAllProject && data.getAllProject?.length > 0) {
        if (!projectId) {
          if (setProjectId) {
            setProjectId(data?.getAllProject[0]?.id)
          }
        }
        setOriginalListing(data.getAllProject)
      }
    },
  })

  //to set the last projectId accessed by user
  const [userAccessGQL, { loading: mutateLoading }] =
    useUserAccessEntityMutation({})
  //#endregion

  //to set the last projectId accessed by user
  const userAccess = (lastProjectId: string) => {
    if (lastProjectId) {
      userAccessGQL({
        variables: { projectId: lastProjectId },
        onCompleted: data => {
          if (data?.updateUserLatestEntity) {
            if (setProjectId) {
              setProjectId(lastProjectId)
            }
            localStorage.setItem('latestProject', lastProjectId)

            let path: string = ''
            switch (page) {
              case 'Home':
                path = `/${lastProjectId}`
                break
              case 'ProjectBusinessInsight':
                path = `/business-insight/${lastProjectId}`
                break
              case 'ProjectSalesPerformance':
                path = `/business-insight/${lastProjectId}/project-sales-performance`
                break
              case 'SalesCommission':
                path = `/sales-commission/${lastProjectId}`
                break
              default:
                path = `/project/${lastProjectId}`
                break
            }
            navTo(`${path}`)
          }
        },
      })
    }
  }

  // useEffect(() => {
  //   if (getAllProject && getAllProject?.length > 0) {
  //     setOriginalListing(getAllProject)
  //   }
  // }, [getAllProject])

  useEffect(() => {
    if (open) {
      fetchProject()
      setSearchInput('')
      handleSearch('', ['name'])
    }
  }, [open])

  const handleClick = (id: string) => {
    if (projectId === id) return false

    userAccess(id)
    onClose()
  }

  //const [switchProjDialog, setSwitchProjDialog] = React.useState(false)

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={onClose}
        className={'switch-project-dialog'}
        header={
          <>
            <Subheader sx={{ padding: '10px 12px' }}>
              <SubheaderText
                primary={
                  <Box>
                    <Box display="flex" margin="2px 0px" width="100%">
                      <Typography
                        className="text-xsTitle"
                        color="#FF9800"
                        component="span"
                        flex="1 1"
                        variant="inherit"
                      >
                        {`Switch ${name}`}
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </Subheader>
            <List
              style={{
                padding: '8px',
              }}
            >
              <SearchBar
                title={
                  <Typography
                    component="span"
                    className="text-mdLabel"
                    variant="inherit"
                  >
                    {`Project Listing`} (
                    <Typography
                      component="span"
                      variant="inherit"
                      className="text-mdLabel search-bar-count-f"
                    >
                      {filteredList?.length}
                    </Typography>
                    )
                  </Typography>
                }
                searchInput={{
                  value: search,
                  onChange: e => {
                    setSearchInput(e.target.value)
                    handleSearch(e.target.value, ['name'])
                  },
                  onChangeClearInput: () => {
                    setSearchInput('')
                    handleSearch('', ['name'])
                  },
                }}
              />
            </List>
          </>
        }
        body={
          <>
            <List>
              {filteredList?.length === 0 ? (
                <EmptyState title="No Record found" />
              ) : (
                <>
                  {filteredList?.map((item, index) => {
                    return (
                      <>
                        <ListItem
                          key={item.id}
                          onClick={() => handleClick(item.id)}
                          className={
                            projectId === item.id ? 'bg-blue-light' : ''
                          }
                        >
                          {/* <IconImg iconimg={CompanyLogo} /> */}
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                component="div"
                                display="flex"
                                variant="inherit"
                                color="common.black"
                              >
                                <Typography
                                  component="span"
                                  className="text-xsTitle"
                                  variant="inherit"
                                  flex="1"
                                >
                                  {item.name}
                                </Typography>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                component="div"
                                display="flex"
                                variant="inherit"
                                color="common.black"
                              >
                                <Typography
                                  component="span"
                                  className="text-desc"
                                  variant="inherit"
                                >
                                  {item.city}, {item.state}
                                </Typography>
                              </Typography>
                            }
                          />
                        </ListItem>
                      </>
                    )
                  })}{' '}
                </>
              )}
            </List>
          </>
        }
        footer={{
          buttons: [
            {
              children: 'Close',
              color: 'primary',
              onClick: onClose,
            },
          ],
        }}
      />
    </>
  )
}
