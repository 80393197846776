import * as React from "react";

export const ProjectCommissionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFD6BD"
      fillRule="evenodd"
      d="m5.771 21.855 1.773 6.615c.428-.356.811-.643 1.168-.867.419-.28.844-.472 1.298-.565.482-.105.962-.086 1.504.038h.002l3.597.826c.82.188 1.59.115 2.318-.305l8.997-5.195a1.69 1.69 0 0 0 .617-2.302 1.69 1.69 0 0 0-2.303-.617L18.477 23.1l.013-.047a1.87 1.87 0 0 0-1.31-2.29l-6.28-1.709a2.95 2.95 0 0 0-2.259.603z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F9C6AA"
      fillRule="evenodd"
      d="M16.422 23.536c.85.231 1.73-.17 2.132-.916a1.87 1.87 0 0 1-2.353 1.742c-1.222-.334-2.447-.656-3.671-.984a.43.43 0 0 1-.303-.525.43.43 0 0 1 .525-.304c1.222.328 2.442.654 3.67.987"
      clipRule="evenodd"
    ></path>
    <path
      fill="#64B5F6"
      fillRule="evenodd"
      d="m6.224 20.453 2.167 8.086a.37.37 0 0 1-.263.455l-1.494.4a.37.37 0 0 1-.455-.262l-2.166-8.086a.37.37 0 0 1 .262-.455l1.495-.4a.37.37 0 0 1 .454.262"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFC700"
      d="M20.222 19.359a5.54 5.54 0 1 0-1.496-10.98 5.54 5.54 0 0 0 1.496 10.98"
    ></path>
    <path
      fill="#F4A40E"
      d="M22.203 16.6a3.866 3.866 0 1 0-5.468-5.468 3.866 3.866 0 0 0 5.468 5.468"
    ></path>
    <path
      fill="#FFC700"
      fillRule="evenodd"
      d="M19.055 11.567a.416.416 0 0 1 .833 0v.299a1.23 1.23 0 0 1 .737.482.415.415 0 0 1-.674.485.385.385 0 0 0-.316-.161h-.253a.39.39 0 0 0-.274.114.387.387 0 0 0 .274.663h.18c.336 0 .642.137.863.359a1.22 1.22 0 0 1 0 1.726 1.2 1.2 0 0 1-.537.314v.316a.416.416 0 0 1-.833 0v-.299a1.23 1.23 0 0 1-.736-.482.415.415 0 0 1 .673-.485q.056.077.14.12a.4.4 0 0 0 .176.041h.254a.39.39 0 0 0 .274-.114.388.388 0 0 0-.274-.663h-.18c-.337 0-.642-.137-.863-.359a1.22 1.22 0 0 1 0-1.726c.147-.147.33-.257.536-.314z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFDA2D"
      d="M13.7 14.728a5.54 5.54 0 1 0-1.496-10.98 5.54 5.54 0 0 0 1.496 10.98"
    ></path>
    <path
      fill="#FDB800"
      d="M13.839 12.995A3.866 3.866 0 1 0 12.06 5.47a3.866 3.866 0 0 0 1.779 7.525"
    ></path>
    <path
      fill="#FFDA2D"
      fillRule="evenodd"
      d="M12.534 6.938a.416.416 0 1 1 .833 0v.299a1.23 1.23 0 0 1 .736.48.416.416 0 0 1-.673.488.385.385 0 0 0-.316-.163h-.254a.39.39 0 0 0-.274.115.387.387 0 0 0 .274.663h.18c.337 0 .642.137.863.358a1.22 1.22 0 0 1 0 1.726 1.2 1.2 0 0 1-.536.314v.316a.416.416 0 0 1-.833 0v-.298a1.23 1.23 0 0 1-.737-.48.416.416 0 0 1 .674-.489.385.385 0 0 0 .316.163h.253a.39.39 0 0 0 .275-.114.387.387 0 0 0-.275-.664h-.18c-.336 0-.642-.137-.863-.358a1.22 1.22 0 0 1 0-1.726c.147-.147.331-.257.537-.314z"
      clipRule="evenodd"
    ></path>
  </svg>
);