import { 
  Dialog, 
  SubheaderText, 
  Subheader,
  MenuItem 
} from "@ifca-ui/core";
import { Box, Typography, TextField, Autocomplete } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useGetAgencyAppointedListingLazyQuery, useGetAllJobPositionLazyQuery, useSubmitCompanyCommissionTeamHeaderMutation } from "generated/graphql";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { setSnackBar } from "helpers/snackBarSubjectHelper";
import { SystemMsgs } from "helpers/Messages/SystemMsg";
import moment from "moment";

const DUMMY_PRINCIPALS = [
  {
    "id": 'fe618e14-8e37-4b89-bb29-53ced8d3a63h',
    "name":"Internal",
    "value":"Internal"
  },
  {
    "id": 'fe618e14-8e37-4b89-bb29-53ced8dajh37',
    "name":"Agent",
    "value":"Agent"
  },
]


interface HeaderFormDialogProps {
  show: boolean;
  mode: 'New' | 'Edit'
  headerData: any;
  onClose: () => void;
  onCompleted: () => void;
}

const HeaderFormDialog = ({show, headerData, mode, onClose, onCompleted}: HeaderFormDialogProps)=>{
    const {control, 
      handleSubmit, 
      reset, 
      formState: { errors },
      setValue, 
      getValues, 
      watch
    } = useForm({
      defaultValues:{
        principal: null,
        position: null,
        agency: null
      }
    });
    const {projectID} = useParams()
    const [jobPositionOptions, setJobPositionOptions] = useState([])
    const [agencyOptions, setAgencyOptions] = useState([])

    const [getAllJobPosition] = useGetAllJobPositionLazyQuery({onCompleted(data) {
      if(!data.getAllJobPosition) return;
      setJobPositionOptions(data.getAllJobPosition)

      if (mode === 'Edit') {
        const position = data.getAllJobPosition.find(
          (el) => el.id === headerData?.positionId
        );
        setValue('position', position);
      }
    }})

    const [getAgencyAppointedListing] =useGetAgencyAppointedListingLazyQuery({onCompleted(data) {
      if(!data.getAgencyAppointedListing) return;
      setAgencyOptions(data.getAgencyAppointedListing)

      if (mode === 'Edit') {
        const agency = data.getAgencyAppointedListing.find(
          (el) => el.id === headerData?.agencyAppointedId
        );
        setValue('agency', agency);
      }
    },})

    const [submitCompanyCommissionTeamHeader] = useSubmitCompanyCommissionTeamHeaderMutation({onCompleted(data, clientOptions) {
      if(data.submitCompanyCommissionTeamHeader){
        setSnackBar(mode === 'New' ?  SystemMsgs.createNewRecord() : SystemMsgs.updateRecord());
        onCompleted()
      }
    }})

    const onSubmit = (data)=> {
      submitCompanyCommissionTeamHeader({
        variables: {
          input: {
            id: mode === 'Edit' ? headerData?.id : null,
            positionId: data.position?.id ?? null,
            agencyAppointedId: data.agency?.id ?? null,
            principal: data.principal.value,
            companyCommissionTeamId: headerData?.companyCommissionTeamId 
          }
        }
      })
    }

    useEffect(()=> {
      getAllJobPosition({variables: {
        projectId: projectID
      }})
      getAgencyAppointedListing()
    },[])

    useEffect(()=> {
      if(mode === 'Edit'){
        const principal = DUMMY_PRINCIPALS.find(
          (el) => el.value === headerData?.principal
        );
        
        setValue('principal', principal);
      }
    } ,[mode])


    return (
      <Dialog fullWidth={true} open={show} onClose={onClose}
        header={
          <Subheader sx={{ padding: '10px 12px' }}>
            <SubheaderText
              primary={
                <Box borderBottom="1px solid #fff">
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="#FF9800"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      Company Commission Structure
                    </Typography>
                    <Typography
                      className="text-xsTitle"
                      color="#000"
                      component="span"
                      flex="1 1"
                      justifyContent={"flex-end"}
                      display={"flex"}
                      variant="inherit"
                    >
                      {mode}
                    </Typography>
                  </Box>
                </Box>
              }
              secondary={
                <Box>
                  <Box display="flex" margin="2px 0px" width="100%">
                    <Typography
                      className="text-xsTitle"
                      color="common.black"
                      component="span"
                      flex="1 1"
                      variant="inherit"
                    >
                      {moment(new Date(headerData.generateDate)).format("D MMMM YYYY")}
                    </Typography>
                    <Typography
                      className="text-xsTitle"
                      color="common.black"
                      component="span"
                      variant="inherit"
                    >
                      
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Subheader>
          }
        body={
          <Box width="100%" display='flex' flexDirection='column' gap='16px'> 
            <Controller
              name='principal'
              control={control}
              rules={{ required: "Principal is required" }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={DUMMY_PRINCIPALS}
                  fullWidth
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Internal/Agent"
                      variant='standard'
                      required
                      size='small'
                      helperText={errors?.principal?.message ?? ""}
                      error={!!errors?.principal?.message}
                    />
                  )}
                  onChange={(_, value) => field.onChange(value ?? null)}
                />
              )}
            />
            {watch('principal')?.value === 'Internal' && (
              <Controller
                name='position'
                control={control}
                rules={{ required: "Job Position is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={jobPositionOptions}
                    fullWidth
                    getOptionLabel={(option) => option.position || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job Position"
                        variant='standard'
                        required
                        size='small'
                        helperText={errors?.position?.message ?? ""}
                        error={!!errors?.position?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value ?? null)}
                  />
                )}
              />
            )}
            {watch('principal')?.value === 'Agent' && (
              <Controller
                name='agency'
                control={control}
                rules={{ required: "Agency is required" }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={agencyOptions}
                    fullWidth
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agency"
                        variant='standard'
                        required
                        size='small'
                        helperText={errors?.agency?.message ?? ""}
                        error={!!errors?.agency?.message}
                      />
                    )}
                    onChange={(_, value) => field.onChange(value ?? null)}
                  />
                )}
              />
            )}
          </Box>
        }
        footer={{
          buttons: [
            {
              children: 'Cancel',
              color: 'primary',
              onClick: () => {
                onClose()
              },
            },
            {
              children: 'Save',
              color: 'primary',
              onClick: () =>{
                handleSubmit(onSubmit)()
              } 
            },
          ],

        }}
      >
      </Dialog>
    )
}

export default HeaderFormDialog;