import * as React from "react";

export const DigitalReportIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <g clipPath="url(#clip0_11655_69372)">
      <path
        fill="#9DC6FB"
        d="M23.758 25.655V4.794A.793.793 0 0 0 22.965 4H6.793A.793.793 0 0 0 6 4.794v20.86a.793.793 0 0 0 .793.794h16.172a.793.793 0 0 0 .793-.793"
      ></path>
      <path
        fill="#80B4FB"
        d="M23.107 4H21.62a.793.793 0 0 1 .793.794v20.86a.793.793 0 0 1-.793.794h1.486a.793.793 0 0 0 .794-.793V4.794A.793.793 0 0 0 23.107 4"
      ></path>
      <path
        fill="#E7ECF6"
        d="M25.86 27.829V6.968a.793.793 0 0 0-.793-.793H8.897a.793.793 0 0 0-.793.793v20.86a.793.793 0 0 0 .794.794h16.17a.794.794 0 0 0 .792-.793"
      ></path>
      <path
        fill="#E28086"
        d="M18.566 12.203h-3.548v3.548a3.55 3.55 0 0 0 3.548-3.548"
      ></path>
      <path
        fill="#95D6A4"
        d="M18.565 12.204a3.548 3.548 0 1 0-3.548 3.548v-3.548z"
      ></path>
      <path
        fill="#407093"
        d="M24.143 10.396h-3.336a.371.371 0 1 1 0-.743h3.336a.371.371 0 0 1 0 .743M24.143 12.316h-3.336a.371.371 0 1 1 0-.742h3.336a.372.372 0 0 1 0 .742M24.143 14.239h-3.336a.371.371 0 1 1 0-.743h3.336a.371.371 0 1 1 0 .743"
      ></path>
      <path
        fill="#ECBE6B"
        d="M13.22 26.447h-1.596a.15.15 0 0 1-.148-.148v-7.096a.15.15 0 0 1 .148-.149h1.596a.15.15 0 0 1 .149.149v7.096a.15.15 0 0 1-.149.148M16.657 26.446H15.06a.15.15 0 0 1-.148-.148v-5.702a.15.15 0 0 1 .148-.148h1.597a.15.15 0 0 1 .148.148v5.702a.15.15 0 0 1-.148.148M20.094 26.447h-1.597a.15.15 0 0 1-.148-.148v-3.932a.15.15 0 0 1 .091-.137.2.2 0 0 1 .057-.012h1.596a.15.15 0 0 1 .149.149v3.928a.15.15 0 0 1-.149.152"
      ></path>
      <path
        fill="#407093"
        d="M24.144 19.429h-4.85a.371.371 0 0 1 0-.743h4.85a.371.371 0 1 1 0 .743M24.143 21.35h-1.52a.371.371 0 1 1 0-.742h1.52a.371.371 0 1 1 0 .743M24.143 23.272h-1.52a.371.371 0 0 1 0-.743h1.52a.371.371 0 1 1 0 .743M24.143 25.192h-1.52a.37.37 0 1 1 0-.743h1.52a.371.371 0 1 1 0 .743"
      ></path>
      <path
        fill="#D8E2F1"
        d="M25.208 6.175h-1.486a.793.793 0 0 1 .793.793v20.86a.793.793 0 0 1-.793.794h1.486a.793.793 0 0 0 .793-.793V6.968a.793.793 0 0 0-.793-.793"
      ></path>
      <path
        fill="#80B4FB"
        d="M6.696 6.968V26.44c.073.009-.026 0 1.404 0V6.968a.793.793 0 0 1 .794-.793H7.49a.793.793 0 0 0-.794.793"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_11655_69372">
        <path fill="#fff" d="M0 0h32v32H0z"></path>
      </clipPath>
    </defs>
  </svg>
);