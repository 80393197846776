import { RouteObject } from "react-router";
import CompanyCommissionTeamListing from "./CompanyCommissionTeamListing";

const CompanyCommissionTeamStructureRoutes: RouteObject[] = [
    {
        path: 'company-commission-team-structure',
        children: [
            {
                path: '',
                element: <CompanyCommissionTeamListing/>
            },
        ]
    }
]

export default CompanyCommissionTeamStructureRoutes;