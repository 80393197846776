import { lazy } from 'react';
import { RouteObject } from 'react-router';

const TaxTransactionCodeListing = lazy(() => import ('./TaxTransactionCodeListing'))
const TaxTransactionCodeForm = lazy(() => import('./TaxTransactionCodeForm'))

const taxTransactionCodeRoutes: Array<RouteObject> = [
  {
    path: 'tax-transaction-code',
    children: [
      {
        path: '',
        element: <TaxTransactionCodeListing />,
      },
      {
        path: `:taxTransactionCodeId/edit`,
        element: <TaxTransactionCodeForm mode="edit" />,
        // element: <TaxEditForm />,
      },
      {
        path: `:add`,
        element: <TaxTransactionCodeForm mode="add" />,
      },
    ]
  },
]

export default taxTransactionCodeRoutes