import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CountryCodeListing = lazy(
  () => import('./CountryCodeListing')
)


const countryCodeRoutes: RouteObject[] = [
  {
    path: 'country-code',
    children: [
      {
        path: '',
        element: <CountryCodeListing />,
      },
    ],
  },
]

export default countryCodeRoutes
