import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const StampDutyListing = lazy(() => import('./StampDutyListing'));
const StampDutyDetail = lazy(() => import('./StampDutyDetail'));
const StampDutyForm = lazy(() => import('./StampDutyForm'));

const stampDutyRoutes: RouteObject[] = [
    {
        path: 'stamp-duty',
        children: [
            {
                path: '',
                element: <StampDutyListing />
            },
            {
                path: ':id',
                element: <StampDutyDetail />
            },
            {
                path: 'add',
                element: <StampDutyForm mode='add'/>
            },
            {
                path: 'edit/:id',
                element: <StampDutyForm mode='edit'/>
            }
        ]
    }
]

export default stampDutyRoutes;