import { lazy } from 'react'
import { RouteObject } from 'react-router'

import { CommissionCalculationDetail } from './CommissionCalculationDetail'
import { CommissionCalculationCreate } from './CommissionCalculationCreate'

const CommisionCalculationListing = lazy(() =>
    import('./CommissionCalculationListing').then(module => ({
      default: module.CommissionCalculationListing,
    }))
)

const CommissionCalculationRoutes: RouteObject[] = [{
    path: 'commission-calculation',
    children: [
      {
        path: '',
        element: <CommisionCalculationListing />,
      },
      {
        path: 'add',
        element: <CommissionCalculationCreate />,
      },
      {
        path: 'edit',
        element: <CommissionCalculationDetail />,
      }
    ]
}]

export default CommissionCalculationRoutes