import * as React from "react";

export const EmployeeJobPositionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path fill="#5FC255" d="M12.465 13.2h7.12V30h-7.12z"></path>
    <path fill="#FB695A" d="M2.334 23.088h7.12V30h-7.12z"></path>
    <path fill="#FAB534" d="M22.546 18.713h7.12V30h-7.12z"></path>
    <path
      fill="#353535"
      d="M26.702 18.713h2.963V30h-2.963z"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M24.172 22.431c-.144 0-.24-.087-.24-.219v-1.575c0-.13.096-.218.24-.218.143 0 .238.087.238.218v1.575c0 .088-.095.22-.238.22M24.172 23.569c-.144 0-.24-.088-.24-.219v-.044c0-.131.096-.219.24-.219.143 0 .238.088.238.22v.043c0 .131-.095.219-.238.219M13.993 16.744c-.143 0-.24-.088-.24-.219V14.95c0-.131.097-.219.24-.219s.239.088.239.219v1.575c0 .088-.096.219-.24.219M13.993 17.881c-.143 0-.24-.087-.24-.218v-.044c0-.131.097-.219.24-.219s.239.088.239.219v.044c0 .13-.096.218-.24.218M3.864 26.369c-.143 0-.239-.088-.239-.219v-1.575c0-.131.096-.219.239-.219s.239.088.239.219v1.575c0 .088-.096.219-.24.219M3.864 27.506c-.143 0-.239-.087-.239-.218v-.044c0-.131.096-.219.239-.219s.239.088.239.219v.044c0 .13-.096.218-.24.218"
    ></path>
    <path
      fill="#353535"
      d="M16.621 13.2h2.963V30H16.62zM6.492 23.088h2.963V30H6.492z"
      opacity="0.25"
    ></path>
    <path
      fill="#F8C6A5"
      d="M26.082 11.231c1.161 0 2.102-.862 2.102-1.925s-.941-1.925-2.102-1.925-2.102.862-2.102 1.925.94 1.925 2.102 1.925"
    ></path>
    <path
      fill="#FAB534"
      d="M30 16.263h-7.836v-.57c0-1.968 1.768-3.587 3.918-3.587S30 13.725 30 15.694z"
    ></path>
    <path
      fill="#353535"
      d="M26.368 9.306c0 .744-.477 1.4-1.194 1.707.287.13.573.175.908.175 1.146 0 2.102-.832 2.102-1.926 0-1.05-.908-1.925-2.102-1.925-.335 0-.622.088-.908.175.717.394 1.194 1.007 1.194 1.794M26.082 12.063c-.287 0-.622.043-.908.087 1.72.35 3.01 1.794 3.01 3.5v.569h1.768v-.569c.048-1.969-1.72-3.588-3.87-3.588"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M24.027 15.213c-.143 0-.239-.088-.239-.22v-.48c0-.132.096-.22.239-.22s.239.088.239.22v.48c0 .088-.143.22-.239.22"
    ></path>
    <path
      fill="#F8C6A5"
      d="M5.918 15.169c1.16 0 2.102-.862 2.102-1.925s-.941-1.925-2.102-1.925-2.103.862-2.103 1.925.942 1.925 2.103 1.925"
    ></path>
    <path
      fill="#FB695A"
      d="M9.836 20.2H2v-.569c0-1.968 1.768-3.587 3.918-3.587s3.918 1.619 3.918 3.587z"
    ></path>
    <path
      fill="#353535"
      d="M6.205 13.244c0 .744-.478 1.4-1.194 1.706.286.131.573.175.908.175 1.146 0 2.102-.831 2.102-1.925 0-1.05-.908-1.925-2.102-1.925-.335 0-.622.088-.908.175.716.394 1.194 1.006 1.194 1.794M5.919 16c-.287 0-.622.044-.908.087 1.72.35 3.01 1.794 3.01 3.5v.57h1.768v-.57C9.837 17.62 8.069 16 5.919 16"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M3.816 19.15c-.143 0-.239-.087-.239-.219v-.481c0-.131.096-.219.24-.219.142 0 .238.088.238.219v.481c0 .088-.096.219-.239.219"
    ></path>
    <path
      fill="#F8C6A5"
      d="M16 5.85c1.161 0 2.102-.862 2.102-1.925S17.161 2 16 2s-2.102.862-2.102 1.925S14.838 5.85 16 5.85"
    ></path>
    <path
      fill="#A0A3F2"
      d="M19.917 10.838h-7.836v-.57c0-1.968 1.768-3.587 3.918-3.587s3.918 1.619 3.918 3.588z"
    ></path>
    <path
      fill="#353535"
      d="M16.285 3.925c0 .744-.478 1.4-1.194 1.706.287.131.573.175.908.175 1.146 0 2.102-.831 2.102-1.925C18.101 2.831 17.145 2 16 2c-.335 0-.622.087-.908.175.716.306 1.194.963 1.194 1.75M15.999 6.681c-.287 0-.622.044-.908.088 1.72.35 3.01 1.793 3.01 3.5v.569h1.768v-.57c.048-1.968-1.72-3.587-3.87-3.587"
      opacity="0.25"
    ></path>
    <path
      fill="#FFFDFD"
      d="M13.897 9.831c-.143 0-.239-.087-.239-.218V9.13c0-.131.096-.218.24-.218.143 0 .238.087.238.218v.482c0 .087-.095.218-.239.218"
    ></path>
  </svg>
);