import { lazy } from 'react'
import { RouteObject } from 'react-router'

const CommissionPaymentForm = lazy(() =>
  import('./CommissionPayment/CommissionPaymentForm').then(module => ({
    default: module.CommissionPaymentForm,
  }))
)

const CompanyRewardListing = lazy(() =>
  import('./CompanyRewardListing').then(module => ({
    default: module.CompanyRewardListing,
  }))
)
const CompanyRewardForm = lazy(() =>
  import('./CompanyRewardForm').then(module => ({
    default: module.CompanyRewardForm,
  }))
)

const CompanyRewardRoutes: RouteObject[] = [
  {
    path: 'company-reward',
    children: [
      {
        path: '',
        element: <CompanyRewardListing />,
      },
      {
        path: 'add',
        element: <CompanyRewardForm mode="New"/>,
      },
      {
        path: ':companyCommissionId',
        children: [
          {
            path: 'edit',
            element: <CompanyRewardForm mode="Edit"/>
          },
          {
            path: 'commission-payment',
            children: [
              {
                path: 'add',
                element: <CommissionPaymentForm />,
              }
            ],
          },
        ],
      },
    ],
  },
]

export default CompanyRewardRoutes
