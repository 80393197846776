import * as React from "react";

export const CommissionCalculationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#E8F1F8"
      d="M22.878 4a1.62 1.62 0 0 0-1.627 1.625v20.13a1.62 1.62 0 0 1-.474 1.15h-.002a1.62 1.62 0 0 1-1.152.475h-14V5.625A1.622 1.622 0 0 1 7.251 4z"
    ></path>
    <path
      fill="#000"
      d="M22.878 4a1.62 1.62 0 0 0-1.627 1.625v20.13a1.62 1.62 0 0 1-.474 1.15h-.002a1.62 1.62 0 0 1-1.152.475h-14v-2.292c7.247-1.357 12.734-7.718 12.734-15.36V4z"
      opacity="0.12"
    ></path>
    <path
      fill="#ACBADF"
      d="M19.625 27.38H5.622A1.624 1.624 0 0 1 4 25.755v-1.223h14.003v1.223c0 .897.726 1.625 1.622 1.625M26.132 22.476h-4.88V5.625A1.622 1.622 0 0 1 22.88 4c.896 0 1.625.729 1.625 1.625v15.223c0 .9.728 1.628 1.627 1.628"
    ></path>
    <path
      fill="#E8F1F8"
      d="M24.504 10.805v10.044a1.627 1.627 0 1 0 3.253 0V10.805z"
    ></path>
    <path
      fill="#475DA9"
      d="M28.999 16.851v11.175a.974.974 0 0 1-.975.974H19.95a.974.974 0 0 1-.974-.974V16.85c0-.538.436-.974.974-.974h8.075c.538 0 .975.436.975.974"
    ></path>
    <path
      fill="#000"
      d="M28.999 16.851v11.175a.974.974 0 0 1-.974.974h-8.076a.974.974 0 0 1-.974-.974v-.377h2.7c3.3 0 5.973-2.673 5.973-5.972v-5.8h.377c.538 0 .974.436.974.974"
      opacity="0.12"
    ></path>
    <path
      fill="#ACBADF"
      d="M27.659 20.095V17.53a.365.365 0 0 0-.366-.366H20.68a.365.365 0 0 0-.366.366v2.564c0 .201.164.365.366.365h6.613a.365.365 0 0 0 .366-.366"
    ></path>
    <path
      fill="#F78721"
      d="M22.194 21.932v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.213-.475.475-.475h.93c.262 0 .475.213.475.475"
    ></path>
    <path
      fill="#E8F1F8"
      d="M24.927 21.932v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.212-.475.475-.475h.93c.262 0 .474.213.474.475M27.659 21.932v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.213-.475.475-.475h.93c.262 0 .475.213.475.475M22.194 24.286v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.213-.475.475-.475h.93c.262 0 .475.213.475.475M24.927 24.286v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.212-.475.475-.475h.93c.262 0 .474.213.474.475M22.194 26.64v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.213-.474.475-.474h.93c.262 0 .475.212.475.474M24.927 26.64v.596a.475.475 0 0 1-.475.475h-.93a.475.475 0 0 1-.475-.475v-.596c0-.262.212-.474.475-.474h.93c.262 0 .474.212.474.474"
    ></path>
    <path
      fill="#FDCF17"
      d="M27.66 24.286v2.95a.475.475 0 0 1-.474.475h-.93a.476.476 0 0 1-.477-.476v-2.95c0-.26.213-.473.476-.473h.931c.26 0 .474.213.474.474"
    ></path>
    <path
      fill="#474747"
      d="M26.072 20.08a.855.855 0 0 1-.853-.854V18.4a.855.855 0 0 1 1.707 0v.826c0 .47-.383.853-.854.853m0-2.002a.32.32 0 0 0-.321.322v.826a.322.322 0 0 0 .643 0V18.4a.32.32 0 0 0-.322-.322"
    ></path>
    <path
      fill="#FDCF17"
      d="M14.252 9.262a3.312 3.312 0 1 1-6.624.002 3.312 3.312 0 0 1 6.624-.002"
    ></path>
    <path
      fill="#F78721"
      d="M11.145 10.778h-1.037a.266.266 0 1 1 0-.532h1.037a.36.36 0 0 0 0-.718h-.412a.892.892 0 0 1 0-1.78h1.037a.266.266 0 1 1 0 .531h-1.037a.36.36 0 0 0 0 .718h.412a.892.892 0 0 1 0 1.781"
    ></path>
    <path
      fill="#F78721"
      d="M10.94 11.297a.266.266 0 0 1-.266-.266v-.519a.266.266 0 1 1 .532 0v.52c0 .146-.12.265-.266.265M10.94 8.28a.266.266 0 0 1-.266-.267v-.52a.266.266 0 1 1 .532 0v.52c0 .147-.12.266-.266.266"
    ></path>
    <path
      fill="#F14F4A"
      d="M9.601 20.831v1.8a.346.346 0 0 1-.346.346H7.973a.346.346 0 0 1-.346-.347v-1.799c0-.191.155-.346.346-.346h1.282c.191 0 .346.155.346.346"
    ></path>
    <path
      fill="#000"
      d="M9.601 20.83v1.8a.346.346 0 0 1-.346.347H7.973a.346.346 0 0 1-.346-.347v-.032a1.63 1.63 0 0 0 1.596-1.628v-.486h.032c.191 0 .346.156.346.347"
      opacity="0.12"
    ></path>
    <path
      fill="#F14F4A"
      d="M12.345 17.867v4.763a.346.346 0 0 1-.346.347h-1.282a.346.346 0 0 1-.346-.347v-4.763c0-.191.155-.346.346-.346H12c.191 0 .346.155.346.346"
    ></path>
    <path
      fill="#000"
      d="M12.345 17.866v4.764a.346.346 0 0 1-.346.347h-1.282a.346.346 0 0 1-.346-.347v-.032a1.63 1.63 0 0 0 1.597-1.628v-3.45h.03c.193 0 .347.156.347.346"
      opacity="0.12"
    ></path>
    <path
      fill="#F14F4A"
      d="M15.09 19.188v3.442a.346.346 0 0 1-.347.347h-1.281a.346.346 0 0 1-.347-.347v-3.442c0-.191.155-.346.347-.346h1.281c.191 0 .346.155.346.346"
    ></path>
    <path
      fill="#000"
      d="M15.09 19.187v3.443a.346.346 0 0 1-.347.347h-1.281a.346.346 0 0 1-.347-.347v-.032a1.63 1.63 0 0 0 1.597-1.628v-2.129h.03c.193 0 .347.156.347.346"
      opacity="0.12"
    ></path>
    <path
      fill="#F14F4A"
      d="M17.834 16.224v6.406a.346.346 0 0 1-.347.347h-1.281a.346.346 0 0 1-.347-.347v-6.406c0-.192.155-.347.347-.347h1.281c.192 0 .347.155.347.347"
    ></path>
    <path
      fill="#000"
      d="M17.833 16.223v6.407a.346.346 0 0 1-.346.347h-1.281a.346.346 0 0 1-.347-.347v-.032a1.63 1.63 0 0 0 1.597-1.628v-5.093h.031c.192 0 .346.155.346.346"
      opacity="0.12"
    ></path>
    <path
      fill="#474747"
      d="M12.347 14.358H7.629a.266.266 0 1 1 0-.532h4.718a.266.266 0 1 1 0 .532M10.812 16.143H7.629a.266.266 0 1 1 0-.532h3.183a.266.266 0 1 1 0 .532"
    ></path>
  </svg>
);