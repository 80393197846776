import { AddBoxOutlined, IndeterminateCheckBoxOutlined as IndeterminateCheckBoxIcon} from '@mui/icons-material';
import AppContext, { AppContextProps } from 'containers/context/Context';
import { IconButton, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useEffect, useContext , useState} from "react";
import useNav from 'components/RouteService/useNav';
import TreeView from '@mui/lab/TreeView';
import TreeItem,{treeItemClasses} from '@mui/lab/TreeItem';
import {
    useMenuOption,
    SubContainerX,
    FloatButton,
    EmptyState,
    ColumnFlex,
    Container,
    SearchBar,
    MenuItem,
    Menu,
    RowFlex,
} from "@ifca-ui/core";
import {
    ExpandMore,
    ExpandLess,
    Add,
    AddCircle,
    MoreVert,
} from "@mui/icons-material";
import FormDialog from './Form/FormDialog';
import HeaderFormDialog from './Form/HeaderFormDialog';
import SubheaderFormDialog from './Form/SubheaderFormDialog';
import { DeleteDialog } from './Form/DeleteDialog';
import { useGetCompanyCommissionTeamListingLazyQuery } from 'generated/graphql';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import moment from 'moment';
import { TopSectionHeader } from 'components/Header/HeaderSection';

const CompanyCommissionTeamStructure = ()=> {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext);
  const { navBack} = useNav();
  const [formDialog, setFormDialog] = useState(false);
  const [headerFormDialog, setHeaderFormDialog] = useState<{show: boolean, mode: 'New' | 'Edit', data: any}>({show: false, mode: 'New', data: null});
  const [subheaderFormDialog, setSubheaderFormDialog] = useState<{show: boolean, mode: 'New' | 'Edit', data: any}>({show: false, mode: 'New', data: null});
  const [deleteDialog, setDeleteDialog] = useState<{show: boolean, data: any}>({show: false, data: null});
  
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const [treeExpanded, setTreeExpanded] = useState([]);

  const {
      anchorEl,
      menu,
      handleClick,
      handleClose
  } = useMenuOption();

  const [getCompanyCommissionTeamListing] = useGetCompanyCommissionTeamListingLazyQuery({onCompleted(data) {
    if(!data.getCompanyCommissionTeamListing) return;
    const companyCommissionTeamListing = 
      data.getCompanyCommissionTeamListing.map(cct => ({...cct, generateDateSearch: moment(cct.generateDate).format("D MMMM YYYY")}))
    
    setOriginalList(companyCommissionTeamListing); 
    setFilteredList(companyCommissionTeamListing)
  }})

  
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => {
              navBack()
            },
          },
        },
        rightIcon: {},
        topSection: {
          smTitle: TopSectionHeader,
          title: `${localStorage.getItem('projectName')}`,
        },
        bottomSection: {
          breadcrumbs: {
            current: 'Company Commission Team Structure',
            maxItems: 2,
          },
          rightText: '',
        },
      },
    })

    getCompanyCommissionTeamListing()
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 400);
    
    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    if (!debouncedSearch) {
      setFilteredList(originalList); // Reset to full list if search is empty
      return;
    }
  
    const lowerSearch = debouncedSearch.toLowerCase();
  
    const filteredData = originalList.reduce((acc, team) => {
      const isDateMatch = team.generateDateSearch.toLowerCase().includes(lowerSearch);
  
      // If date matches, keep the entire team as is (all headers & subheaders)
      if (isDateMatch) {
        acc.push(team);
        return acc;
      }
  
      const filteredHeaders = team.headers.reduce((headerAcc, header) => {
        // Filter subheaders
        const filteredSubheaders = header.subheaders.filter(subheader =>
          (subheader.position?.position.toLowerCase().includes(lowerSearch) ?? false) ||
          (subheader.agency?.name.toLowerCase().includes(lowerSearch) ?? false)
        );
  
        // Check if header itself matches
        const isHeaderMatch =
          (header.position?.position.toLowerCase().includes(lowerSearch) ?? false) ||
          (header.agency?.name.toLowerCase().includes(lowerSearch) ?? false);
  
        // If header matches, keep it with ALL subheaders
        if (isHeaderMatch) {
          headerAcc.push({ ...header, subheaders: header.subheaders });
          return headerAcc;
        }
  
        // If only subheaders match, keep only the matching ones
        if (filteredSubheaders.length > 0) {
          headerAcc.push({ ...header, subheaders: filteredSubheaders });
        }
  
        return headerAcc;
      }, []);
  
      // Only add the team if it has matching headers
      if (filteredHeaders.length > 0) {
        acc.push({ ...team, headers: filteredHeaders });
      }
  
      return acc;
    }, []);
  
    setFilteredList(filteredData);
  }, [debouncedSearch, originalList]);

  const handleAccordionChange =
  (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded((prev) => {
      if (!newExpanded) {
        const { [panel]: _, ...rest } = prev; // Remove the key if newExpanded is false
        return rest;
      }
      return { ...prev, [panel]: newExpanded };
    });
  };

  const handleToggle = (_, nodeIds) => {
    setTreeExpanded(nodeIds);
  };

  return (
    <>
      <SubContainerX>
        <SearchBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              {`Company Commission Team Structure Listing`} (
              <Typography
                component="span"
                variant="inherit"
                className="text-mdLabel search-bar-count-f"
              >
                {filteredList?.length}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: search,
            onChange: e => {
              setSearch(e.target.value)
            },
            onChangeClearInput: () => {
              setSearch('')
            },
          }}
        />
      </SubContainerX>
      <Container>
        <ColumnFlex>
          {filteredList.length === 0 ? 
            (<EmptyState title="No Record found" />) :
            (filteredList.map((team, index) => (
              <Box sx={{marginBottom: '4px'}} key={team.id}>
                <Accordion
                  key={team.id}
                  expanded={Boolean(expanded[team.id])}
                  onChange={handleAccordionChange(`${team.id}`)}
                  disableGutters
                >
                  <AccordionSummary
                    sx={{ 
                      backgroundColor: !!expanded[team.id] ? '#ffe7ba' : '',
                    }}
                    aria-controls={`panel${team.id + 1}-content`}
                    id={`panel${team.id + 1}-header`}
                  >
                    <RowFlex crossAxisAlignment='center' fullWidth>
                      <Box display='flex' flexDirection='column' flexGrow={1}>
                        <RowFlex crossAxisAlignment='center' gap='8px'>
                          <Typography className='text-xsTitle'>{moment(team.generateDate).format("D MMMM YYYY")}</Typography>
                          {!!expanded[team.id] && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation()
                                setHeaderFormDialog({show: true, data: { generateDate: team.generateDate, companyCommissionTeamId: team.id } , mode: 'New'})
                              }} 
                              size='small'
                              sx={{padding: 0}}
                              >
                                <AddCircle color='primary'/>
                            </IconButton>)
                          }
                        </RowFlex>
                        {!expanded[team.id] && (
                          <Typography className="text-desc">
                            <span className="text-label highlight-text">{team.headers.length}</span>
                            &nbsp;Records
                          </Typography>
                        )}
                      </Box>
                      <IconButton size='small'>
                        {!!expanded[team.id] ? <ExpandLess/> : <ExpandMore/>}
                      </IconButton>
                    </RowFlex>
                  </AccordionSummary>
                  <AccordionDetails>
                    {team.headers.length === 0 ? (
                      <EmptyState title="No Record found" />
                    ) : (
                      <Box display='flex' flexDirection='column' gap='8px'>
                        <TreeView
                          aria-label="multi-level tree"
                          onNodeToggle={handleToggle}
                          expanded={treeExpanded}
                          sx={{
                            background: "white",
                            marginTop: 0,
                            position: "relative",
                            padding: "16px",
                            borderRadius: "4px",
                            boxShadow: `
                              0 3px 1px -2px rgba(0, 0, 0, 0),
                              0 1px 10px 2px rgba(0, 0, 0, 0.05),
                              0 0px 5px 0 rgba(0, 0, 0, 0.1)
                            `,
                            [`& .${treeItemClasses.group}`]: {
                              marginLeft: "14.5px",
                              paddingLeft: "12px",
                              borderLeft: "1px dashed black",
                            },
                            [`& .${treeItemClasses.content}`]: { marginBottom: "0px" },
                            [`& .${treeItemClasses.label}`]: { paddingLeft: "8px" },
                            "& .MuiTreeItem-group": {
                              marginLeft: "14.5px",
                              paddingLeft: "12px",
                              borderLeft: "1px dashed black",
                            },
                            [`& .${treeItemClasses.selected}`]: {
                              backgroundColor: "white !important",
                            },
                          }}
                        >
                          {team.headers.map((header, headerIndex) => (
                            <TreeItem
                              nodeId={String(header.id)}
                              key={String(header.id)}
                              icon={
                                treeExpanded.includes(String(header.id)) ? (
                                  <IndeterminateCheckBoxIcon style={{ color: "#212121" }} />
                                ) : (
                                  <AddBoxOutlined style={{ color: "#212121" }} />
                                )
                              }
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Box sx={{ display: "flex", gap: 2 }}>
                                    <Typography className="text-xsTitle">
                                      {header.principal === 'Internal' ? header.position.position : header.agency.name}
                                    </Typography>
                                  </Box>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleClick(
                                        e, 
                                        header.id, 
                                        headerIndex, 
                                        { ...header, 
                                          type: 'Company Commission Team Header', 
                                          generateDate: team.generateDate
                                        }
                                      )
                                    }}
                                    size="small"
                                  >
                                    <MoreVert />
                                  </IconButton>
                                </Box>
                              }
                            > 
                              {header.subheaders.length > 0 ? (
                                header.subheaders.map((subheader, subheaderIndex) => (
                                  <TreeItem
                                    nodeId={String(subheader.id)}
                                    key={String(subheader.id)}
                                    icon={<span style={{ fontSize: "24px" }}>{`\u2022`}</span>}
                                    label={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography className="text-xsTitle">
                                          {subheader.principal === 'Internal' ? subheader.position.position : subheader.agency.name}                                          </Typography>
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleClick(
                                              e, 
                                              subheader.id, 
                                              subheaderIndex, 
                                              { ...subheader, 
                                                header, 
                                                type: "Company Commission Team Subheader", 
                                                generateDate: team.generateDate 
                                              }
                                            );
                                          }}
                                          size="small"
                                        >
                                          <MoreVert />
                                        </IconButton>
                                      </Box>
                                    }
                                  />
                                ))
                              ) : (
                                <div/>
                              )}
                            </TreeItem>
                          ))}
                        </TreeView>
                      </Box>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            )))
          }
        </ColumnFlex>
      </Container>

      <FloatButton color='primary' onClick={()=>{setFormDialog(true)}}>
        <Add />
      </FloatButton>

      {formDialog &&
        <FormDialog
          onCompleted={()=>{
            getCompanyCommissionTeamListing()
            setFormDialog(false)
          }}
          show={formDialog}
          onClose={()=>setFormDialog(false)}
        />
      }
      {headerFormDialog.show &&
        <HeaderFormDialog
          show={headerFormDialog.show}
          mode={headerFormDialog.mode}
          headerData={headerFormDialog.data}
          onClose={()=> setHeaderFormDialog((hfd => ({...hfd, show: false})))}
          onCompleted={()=> {
            getCompanyCommissionTeamListing()
            setHeaderFormDialog((hfd => ({...hfd, show: false})))
          }}
        />
      }
      {subheaderFormDialog.show &&
        <SubheaderFormDialog
          show={subheaderFormDialog.show}
          mode={subheaderFormDialog.mode}
          subheaderData={subheaderFormDialog.data}
          onClose={()=> setSubheaderFormDialog((hfd => ({...hfd, show: false})))}
          onCompleted={()=>{
            getCompanyCommissionTeamListing()
            setSubheaderFormDialog((hfd => ({...hfd, show: false})))
          }}
        />
      }
      {deleteDialog.show &&
        <DeleteDialog
          show={deleteDialog.show}
          data={deleteDialog.data}
          onClose={()=> setDeleteDialog({show: false, data: null})}
          onCompleted={()=>{
            getCompanyCommissionTeamListing()
            setDeleteDialog({show: false, data: null})
          }}
        />
      }
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {menu?.data?.type === 'Company Commission Team Header' && (
          <MenuItem onClick={()=>{
            setSubheaderFormDialog({
              show: true,  
              mode: 'New', 
              data: { 
                companyCommissionTeamHeaderId: menu?.data?.id, 
                generateDate: menu?.data?.generateDate,
                header: menu.data 
              }
            })
          }}>
            Add Sub
          </MenuItem>
        )}
        <MenuItem onClick={()=>{
          const menuType = menu?.data?.type;
          
          switch (menuType) {
            case 'Company Commission Team Header':
              setHeaderFormDialog({show: true, mode: 'Edit', data: menu.data})
              break;

            case 'Company Commission Team Subheader':
              setSubheaderFormDialog({show: true, mode: 'Edit', data: menu.data})
              break;
        
            default:
              return;
          }
        }}>
          Edit
        </MenuItem>
        <MenuItem onClick={()=>{
          setDeleteDialog({show: true, data: menu?.data})
        }}>
          Delete
        </MenuItem>
      </Menu>
      </>
  )
}

export default CompanyCommissionTeamStructure;