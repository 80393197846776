import { Close, Mic, Search, Tune } from '@mui/icons-material'
import {
  Badge,
  type BadgeProps,
  IconButton,
  type IconButtonProps,
  InputBase,
  type InputBaseProps,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  type ListProps,
  useIsFocusVisible,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import * as React from 'react'

const SearchRoot = styled(List)`
  width: 100%;
  z-index: 100;
  background: #fff;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 10px 2px rgba(0, 0, 0, 0.05),
    0 0px 5px 0 rgba(0, 0, 0, 0.1);

  .MuiListItem-root {
    padding: 10px 12px;
  }
  .MuiListItemText-root {
    margin: 0px;
    display: flex;
    padding: 2px 0px;
  }
  .MuiIconButton-edgeEnd {
    padding: 8px;
    margin-right: -8px;
    &.MuiButtonBase-root:first-child {
      padding-right: 10px;
    }
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
  .search-field {
    position: absolute;
    width: 100%;
    background: #fff;
    margin-top: -38px;
  }
  .MuiInputBase-root {
    /* width: 100%; */
    .MuiInputBase-input {
      padding: 0px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
  }
`
interface SearchInputProps extends InputBaseProps {
  // onChangeClearInput will result in React giving an error because onChange* is a reserved prop
  handleClearInput?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onChangeClearInput?: SearchInputProps['handleClearInput']
}
interface SearchBarProps {
  title?: React.ReactNode
  searchInput?: SearchInputProps
  isAdvanceSearch?: boolean
  advanceSearch?: IconButtonProps,
  badgeContent?: number,
  isMic?: boolean
  mic?: IconButtonProps
  isOption?: boolean
  option?: {
    props?: IconButtonProps
    badge?: BadgeProps
  }
}

export const SearchBar: React.FC<SearchBarProps> = ({
  title,
  searchInput,
  isAdvanceSearch = false,
  advanceSearch,
  badgeContent = 0,
  isMic = false,
  mic,
  isOption = false,
  option,
  ...props
}: SearchBarProps) => {
  const [search, setSearch] = React.useState(false)
  const searchIconOnClick = (e: any): void => {
    setSearch(!search)
    // onChangeClearInput will result in React giving an error because onChange* is a reserved prop
    searchInput?.handleClearInput?.(e) ?? searchInput?.onChangeClearInput?.(e)
  }
  const inputRef = React.useRef(null)

  return (
    <SearchRoot disablePadding id="search-bar" {...props}>
      <ListItem>
        <ListItemText
          disableTypography
          primary={<> {title} </>}
          onClick={(_: any) => {
            setSearch(true)
          }}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="search"
            onClick={searchIconOnClick}
          >
            <Search sx={{ color: '#212121' }} />
          </IconButton>
          {isAdvanceSearch && (
            <IconButton edge="end" aria-label="delete" {...advanceSearch}>
              <Badge 
                badgeContent={badgeContent} 
                color="primary" 
                sx={{ "& .MuiBadge-badge": { 
                  fontSize: "10px", 
                  height: '16px !important', 
                  minWidth: '16px !important', 
                  padding: '0 !important'
                } }}
                >
                <Tune sx={{ color: '#212121' }} />
              </Badge>
            </IconButton>
          )}
          {isOption && (
            <Badge
              {...option?.badge}
              sx={{
                '& .MuiBadge-badge': {
                  height: '14px',
                  minWidth: '14px',
                  padding: '4px',
                  marginTop: '2px',
                  marginBottom: '2px',
                },
              }}
            >
              <IconButton edge="end" aria-label="delete" {...option?.props} />
            </Badge>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        tabIndex={-1}
        id="search-field"
        disablePadding
        className="search-field"
        sx={{
          display: !search && !searchInput?.value ? 'none' : null,
          '& .MuiInputBase-root': {
            width: '100%',
          },
        }}
      >
        <ListItemText
          disableTypography
          sx={{
            padding: '0px !important',
          }}
          primary={
            <>
              {' '}
              <InputBase
                placeholder="Search here..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchInput?.value ?? ''}
                onChange={searchInput?.onChange}
                ref={inputRef}
                inputRef={input => input && input.focus()}
                // {...searchInput} // this had to be commented out because DOM elements can't accept onChangeClearInput or handleClearInput
              />{' '}
            </>
          }
        />
        <ListItemSecondaryAction>
          {isMic && (
            <IconButton
              edge="end"
              aria-label="mic"
              sx={{
                marginTop: '-38px',

                display: !search && !searchInput?.value ? 'none' : null,
              }}
              {...mic}
            >
              <Mic />
            </IconButton>
          )}
          <IconButton
            edge="end"
            aria-label="close"
            sx={{
              marginTop: '-38px',
              display: !search && !searchInput?.value ? 'none' : null,
            }}
            onClick={(e: any) => {
              searchIconOnClick(e)
              searchInput?.handleClearInput?.(e) ??
                searchInput?.onChangeClearInput?.(e)
              // searchInput.onChange = e =>
              // searchInput.value = ''
            }}
          >
            <Close sx={{ color: '#212121' }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </SearchRoot>
  )
}
