import React, { lazy, useEffect } from 'react'
import { RouteObject, useNavigation } from 'react-router'

const PackageInstallmentDetail = lazy(()=> import ('./preview/PackageInstallmentDetail'))

const UnitTransferIndoListing = lazy(() => import('./UnitTransferIndoListing'))

const UnitTransferIndoForm = lazy(() => import('./UnitTransferIndoForm'))

const UnitTransferPreview = lazy(() => import('./preview/UnitTransferPreview'))

const UnitTransferIndoDetail = lazy(() => import('./UnitTransferIndoDetail'))

const PurchaserInfoForm = lazy(
  () => import('./viewTabs/purchaser/PurchaserInfoForm')
)
const PurchaserDetailsPreview = lazy(() => import('./viewTabs/purchaser/PurchaserDetailsPreview'))

const UnitPrice = lazy(() => import('./viewTabs/pricing/UnitPrice'))

const UnitPricePreview = lazy(() => import('./viewTabs/pricing/UnitPricePreview'))

const CarPark = lazy(() => import('./viewTabs/pricing/carpark/CarPark'))

const CarParkForm = lazy(() => import('./viewTabs/pricing/carpark/CarParkForm'))

const InstallmentCarParkForm = lazy(
  () => import('./viewTabs/pricing/carpark/InstallmentCarParkForm')
)

const CarParkDetails = lazy(
  () => import('./viewTabs/pricing/carpark/CarParkDetails')
)

const CarParkPaymentDetails = lazy(
  () => import('./viewTabs/pricing/carpark/CarParkPaymentDetails')
)
const InstallmentCarPark = lazy(
  () => import('./viewTabs/pricing/carpark/InstallmentCarPark')
)

const CarParkPreview = lazy(
  () => import('./viewTabs/pricing/carpark/CarParkPreview')
)

const AdditionalPackage = lazy(
  () => import('./viewTabs/pricing/additionalPackages/AdditionalPackage')
)

const PackagePaymentDetails = lazy(
  () => import('./viewTabs/pricing/additionalPackages/PackagePaymentDetails')
)

const AdditionalPackagePreview = lazy(
  () => import('./viewTabs/pricing/additionalPackages/AdditionalPackagePreview')
)

const AdditionalPackageForm = lazy(
  () => import('./viewTabs/pricing/additionalPackages/AdditionalPackageForm')
)

const AdditionalPackageInstallmentSchedule = lazy(
  () => import('./viewTabs/pricing/additionalPackages/AdditionalPackageInstallmentSchedule')
)

const AdditionalPackageDetails = lazy(
  () => import('./viewTabs/pricing/additionalPackages/AdditionalPackageDetails')
)

const InstallmentScheduleForm = lazy(
  () => import('./viewTabs/paymentPlan/InstallmentScheduleForm')
)

const ContactPersonForm = lazy(() => import('./viewTabs/others/ContactPersonForm'))

const DiscountListing = lazy(
  () => import('./viewTabs/pricing/discount/DiscountListing')
)

const DiscountDetail = lazy(
  () => import('./viewTabs/pricing/discount/DiscountDetail')
)

const unitTransferSubforms = [
  {
    path: 'purchaser-info/add',
    element: <PurchaserInfoForm mode="add" formMode="Add" />,
  },
  {
    path: 'purchaser-info/editJointBuyer',
    element: (
      <PurchaserInfoForm mode="editJointBuyer" formMode="EditJointBuyer" />
    ),
  },
  {
    path: 'purchaser-info/editMainBuyer',
    element: (
      <PurchaserInfoForm mode="editMainBuyer" formMode="EditMainBuyer" />
    ),
  },
  {
    path: 'unit-price/:unitId',
    element: <UnitPrice />,
  },
  {
    path: 'car-park',
    element: <CarPark />,
  },
  {
    path: 'car-park/form',
    element: <CarParkForm mode="add" formMode="add" />,
  },
  {
    path: 'car-park/form/:carParkId',
    element: <CarParkForm mode="edit" formMode="edit" />
  },
  {
    path: 'car-park/add/installment-schedule/:paymentPlanId',
    element: <InstallmentCarParkForm mode="add" formMode="add" />,
  },
  {
    path: 'car-park/details/:carParkId',
    element: <CarParkDetails mode="details" />,
  },
  {
    path: 'car-park/details/:carParkId/:paymentPlanDetailsId',
    element: <CarParkPaymentDetails mode="detailspayment" />,
  },
  {
    path: 'car-park/details/:carParkId/:paymentPlanDetailsId/form',
    element: <InstallmentCarPark mode="detailspaymentform" />,
  },
  {
    path: 'additional-package',
    children: [
      {
        path: '',
        element: <AdditionalPackage />,
      },
      {
        path: 'view/:packageId',
        element: <AdditionalPackageDetails />,
      },
      {
        path: 'view/:packageId/details',
        element: <PackagePaymentDetails />,
      },
      {
        path: 'details',
        element: <AdditionalPackageDetails />,
      },
      {
        path: 'form',
        children: [
          {
            path: '',
            element: <AdditionalPackageForm mode="add" />,
            // element: <AdditionalPackageForm mode="add" />,
          },
          {
            path: 'installment-schedule/:paymentPlanId',
            element: <AdditionalPackageInstallmentSchedule />,
          },
          {
            path: ':packageId',
            children: [
              {
                path: '',
                element: <AdditionalPackageForm mode="edit" />,
              },
              {
                path: ':paymentPlanId',
                element: <AdditionalPackageInstallmentSchedule />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'discount',
    element: <DiscountListing />,
  },
  // {
  //   path: 'discount/details',
  //   element: <DiscountDetail />,
  // },
  {
    path: 'installment-schedule/form',
    element: <InstallmentScheduleForm />,
  },
  {
    path: 'contact-person/add',
    element: <ContactPersonForm mode="add" />,
  },
  {
    path: 'contact-person/edit/:contactId',
    element: <ContactPersonForm mode="edit" />,
  },
]

const UnitTransferIndoRoutes: RouteObject[] = [
  {
    path: 'unit-transfer',
    children: [
      {
        path: '',
        element: <UnitTransferIndoListing />,
      },
      // {
      //   path: 'form',
      //   element: <UnitTransferIndoForm mode="add" />,
      // },
      {
        path: 'detail/:transferId',
        element: <UnitTransferIndoDetail />,
      },
      {
        path: 'form',
        children: [
          {
            path: '',
            element: <UnitTransferIndoForm />,
          },
          ...unitTransferSubforms,
        ],
      },
      {
        path: 'form/edit/:transferId',
        children: [
          {
            path: '',
            element: <UnitTransferIndoForm />,
          },
          ...unitTransferSubforms,
        ],
      },
      {
        path: 'form/preview/',
        children: [
          {
            path: 'add',
            element: <UnitTransferPreview />,
          },
          {
            path: 'edit/:transferId',
            element: <UnitTransferPreview />,
          },
          {
            path: 'edit/:transferId/package/:packageId',
            element: <PackageInstallmentDetail />,
          },
          {
            path: 'purchaser-info/:contactId',
            element: <PurchaserDetailsPreview />,
          },
          {
            path: 'unit-price',
            element: <UnitPricePreview />,
          },
          {
            path: 'car-park',
            element: <CarParkPreview />,
          },
          {
            path: 'car-park/details',
            element: <CarParkDetails mode="details" />,
          },
          {
            path: 'additional-package',
            element: <AdditionalPackagePreview />,
          },
          {
            path: 'additional-package/details',
            element: <AdditionalPackageDetails />,
          },
        ],
      },
    ],
  },
]

export default UnitTransferIndoRoutes
