import { RouteObject } from "react-router";
import CommissionTypeListing from "./CommissionTypeListing";
import CommissionTypeForm from "./CommissionTypeForm";

const CommissionTypeRoutes: RouteObject[] = [
    {
        path: 'commission-type',
        children: [
            {
                path: '',
                element: <CommissionTypeListing/>
            },
            {
                path: 'add',
                element: <CommissionTypeForm mode="new"/>
            },
            {
                path: 'edit/:id',
                element: <CommissionTypeForm mode="edit"/>
            },
        ]
    }
]

export default CommissionTypeRoutes;