let accessToken = ''

export const setAccessToken = (s: string) => {
  accessToken = s
  sessionStorage.setItem('tokenKey', s)
}

export const getAccessToken = () => {
  return sessionStorage.getItem('tokenKey')
}

export const getCookie = (name : any) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const [key, value] = cookie.split("="); 
      if (key === name) {
        return decodeURIComponent(value); 
      }
    }
    return null; 
  }

export const clearAccessToken = () => {
  sessionStorage.removeItem('tokenKey')
}

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos).trim() : cookie.trim();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  });
};
