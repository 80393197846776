import { Dialog } from 'components/Dialogs/Dialog'
import { Card } from '@ifca-ui/core'
import { Box, Typography } from '@mui/material'
import { CommissionTypeEntity } from 'generated/graphql'

interface ICommissionItemDetailProps {
  showDialog: boolean
  data: CommissionTypeEntity
  onCloseDialog: ()=> void
}

const CommissionTypeDetailDialog = ({
  showDialog,
  data,
  onCloseDialog,
}: ICommissionItemDetailProps) => {

  return (
    <Dialog
      useDefaultDialogHeader
      open={showDialog}
      onClose={onCloseDialog}
      defaultDialogData={{
        header: {
          title: {
            leftTopTitle: {
              title: {
                text: data?.name,
              },
            },
            rightTopTitle: {
              text: 'View',
            },
          },
        },
      }}
      body={
        <Card className="card-container-detail" sx={{boxShadow: 'none', padding: '0'}}>
          <Box component="div" display="flex">
            <Box width="100%" boxSizing="border-box" marginBottom="14px">
              <Typography className="label-f">Commission Pay Out Type</Typography>
              <Typography className="text-xsTitle" sx={{textTransform: 'capitalize'}}>{data?.payOutType?.toLowerCase()}</Typography>
            </Box>
            <Box width="100%" boxSizing="border-box" marginBottom="14px">
              <Typography className="label-f">Target By</Typography>
              <Typography className="text-xsTitle" sx={{textTransform: 'capitalize'}}>{data?.targetBy?.toLowerCase()}</Typography>
            </Box>
          </Box>
          <Box component="div" display="flex">
            <Box width="100%" boxSizing="border-box" marginBottom="14px">
              <Typography className="label-f">Calc. Interval In Month(s)</Typography>
              <Typography className="text-xsTitle" sx={{textTransform: 'capitalize'}}>{data?.interval}</Typography>
            </Box>
          </Box>
        </Card>
      }
      footer={{
        buttons: [
          {
            children: 'Close',
            onClick: () => onCloseDialog(),
          }
        ]
      }}
    />
  )
}

export default CommissionTypeDetailDialog
