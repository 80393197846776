import { lazy } from 'react'
import { RouteObject } from 'react-router'

const ProjectCommissionPackageListing = lazy(() => 
  import('./ProjectCommissionPackageListing')
    .then(module => ({
      default: module.ProjectCommissionPackageListing
    })
  )
)

const PackageDetailForm = lazy(() =>
  import('./PackageDetailForm')
    .then(module => ({
      default: module.PackageDetailForm
    })
  )
)

const ProjectCommissionPackageTarget: RouteObject[] = [
  {
    path: 'project-commission-package-target',
    children: [
      {
        path: '',
        element: <ProjectCommissionPackageListing />
      },
      {
        path: ':packageId/project-commission-package-target-detail/add',
        element: <PackageDetailForm mode='New'/>
      },
      {
        path: ':packageId/project-commission-package-target-detail/:packageDetailId/edit',
        element: <PackageDetailForm mode='Edit'/>
      },
    ]
  }
]

export default ProjectCommissionPackageTarget