import { lazy } from 'react';
import { RouteObject } from 'react-router';

const ProjectCommissionTeamStructureListing = lazy(() => import ('./ProjectCommissionTeamStructureListing'))

const projectCommissionTeamStructureRoutes: Array<RouteObject> = [
  {
    path: 'project-commission-team-structure',
    element: <ProjectCommissionTeamStructureListing />,
  }
]

export default projectCommissionTeamStructureRoutes