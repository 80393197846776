import {lazy} from 'react'
import { RouteObject } from 'react-router'

const CompanyCommissionPackageListing = lazy(() => 
import('./CompanyCommissionPackageListing').then(module => ({
    default: module.CompanyCommissionPackageListing
})))

const PackageDetailForm = lazy(() =>
import('./PackageDetailForm').then(module => ({
    default: module.PackageDetailForm
})))

const CompanyCommissionPackageTarget: RouteObject[] = [
    {
        path: 'company-commission-package-target',
        children: [
            {
                path: '',
                element: <CompanyCommissionPackageListing />
            },
            {
                path: ':packageId/company-commission-package-target-detail/add',
                element: <PackageDetailForm mode='New'/>
            },
            {
                path: ':packageId/company-commission-package-target-detail/:packageDetailId/edit',
                element: <PackageDetailForm mode='Edit'/>
            },
        ]
    }
]

export default CompanyCommissionPackageTarget