import { lazy } from 'react'
import { RouteObject } from 'react-router'

const ProjectCommissionForm = lazy(() =>
  import('./ProjectCommissionForm').then(module => ({
    default: module.ProjectCommissionForm,
  }))
)

const ProjectCommissionListing = lazy(() =>
  import('./ProjectCommissionListing').then(module => ({
    default: module.ProjectCommissionListing,
  }))
)

const CommissionPaymentForm = lazy(() =>
  import('./CommissionPayment/CommissionPaymentForm').then(module => ({
    default: module.CommissionPaymentForm,
  }))
)

const ProjectCommissionRoutes: RouteObject[] = [
  {
    path: 'project-commission',
    children: [
      {
        path: '',
        element: <ProjectCommissionListing />,
      },
      {
        path: 'add',
        element: <ProjectCommissionForm mode='add' />,
      },
      {
        path: ':ID',
        children: [
          {
            path: 'edit',
            element: <ProjectCommissionForm mode='edit' />
          },
          {
            path: 'commission-payment',
            children: [
              {
                path: 'add',
                element: <CommissionPaymentForm />,
              }
            ],
          },
        ],
      },
    ],
  },
]

export default ProjectCommissionRoutes
