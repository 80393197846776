import {
  useAddPriceAdjustmentMutation,
  useEditPriceAdjustmentMutation,
  useGetPriceAdjustmentByIdLazyQuery,
  useGetPriceAdjustmentListLazyQuery,
  useGetReasonListLazyQuery,
  useGetRoundingLazyQuery,
  useGetSelectUnitAtPriceAdjustmentLazyQuery,
  useSoftDeletePriceAdjustmentMutation,
  useUpdatePriceAdjustmentStatusMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { useNavigate, useParams } from 'react-router'
import { UnitPriceInput } from '../UnitPricingForm'
import useNav from 'components/RouteService/useNav'

export const computationModeList = [
  { name: 'New Price = Value' },
  { name: 'Original Price + Value' },
  { name: 'Original Price x Value' },
]

export interface listingProps {
  id: string
  name: string
}

export let roundingPrecisionListing = []
export let roundingModeListing = []

export const useGetPriceAdjustmentList = (setOriginalListing, selectedStatus) => {
  const { projectID }: any = useParams()
  const [
    fetchPriceAdjustmentList,
    {
      data: { getPriceAdjustmentList: priceAdjustmentList } = {
        getPriceAdjustmentList: null,
      },
      refetch: refetchData,
      loading: loadingState,
      error: errorState,
    },
  ] = useGetPriceAdjustmentListLazyQuery({
    onCompleted: data => {
      setOriginalListing(data.getPriceAdjustmentList)
    },
    variables: { projectId: projectID, statusFilter:  selectedStatus },
  })

  return {
    priceAdjustmentList,
    loadingState,
    errorState,
    fetchPriceAdjustmentList,
    refetchData,
  }
}
export const useGetPriceAdjustmentById = reset => {
  const { projectID }: any = useParams()
  const [
    fetchData,
    {
      data: { getPriceAdjustmentById: priceAdjustment } = {
        getPriceAdjustmentById: null,
      },
      refetch: refetchData,
      loading: priceAdjustmentByIdLoading,
      error: errorState,
    },
  ] = useGetPriceAdjustmentByIdLazyQuery({
    onCompleted: ({ getPriceAdjustmentById }) => {
      const foundRoundingPrecision = roundingPrecisionListing.find(
        x => x.id === getPriceAdjustmentById?.roundingPrecision
      )
      const foundRoundingMode = roundingModeListing.find(
        x => x.id === getPriceAdjustmentById?.roundingMode
      )
      const foundComputationMode = computationModeList.find(
        x => x.name === getPriceAdjustmentById?.computationMode
      )
      reset({
        title: getPriceAdjustmentById.name,
        roundingPrecision: foundRoundingPrecision,
        roundingMode: foundRoundingMode,
        computationValue: getPriceAdjustmentById.computationValue,
        computationMode: foundComputationMode,
        reason: getPriceAdjustmentById.defaultReason[0],
        remark: getPriceAdjustmentById.remark,
      })
    },
  })

  const fetchPriceAdjustmentData = id => {
    fetchData({
      variables: {
        projectId: projectID,
        id: id,
      },
    })
  }

  return {
    priceAdjustment,
    priceAdjustmentByIdLoading,
    errorState,
    fetchPriceAdjustmentData,
    refetchData,
  }
}

export const useAddPriceAdjustment = () => {
  let navigate = useNavigate()
  const { navBack } = useNav()
  const { projectID }: any = useParams()
  const [addPriceAdjustment, { loading: isLoadingAdd }] =
    useAddPriceAdjustmentMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.addPriceAdjustment) {
          setSnackBar(SystemMsgs.createNewRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })

  const addData = (data: UnitPriceInput, status, selectedUnit) => {
    const unitIdList = selectedUnit?.map(x => x.unitId)
    if (unitIdList.length == 0 || unitIdList.length == null) {
      return setSnackBar('Please select at least one(1) unit to save')
    }

    addPriceAdjustment({
      variables: {
        input: {
          projectId: projectID,
          computationMode: data.computationMode.name,
          computationValue: parseFloat(data.computationValue.toString()),
          name: data.title,
          reason: data.reason.id,
          roundingMode: data.roundingMode.id,
          roundingPrecision: data.roundingPrecision.id,
          unitIdList: unitIdList,
          remark: data?.remark,
          status: status,
        },
      },
    })
  }
  return {
    addData,
    isLoadingAdd,
  }
}

export const useEditPriceAdjustment = () => {
  let navigate = useNavigate()
  const { navBack } = useNav()
  const { projectID, id }: any = useParams()
  const [editPriceAdjustment, { loading: isLoadingEdit }] =
    useEditPriceAdjustmentMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        if (data?.editPriceAdjustment) {
          setSnackBar(SystemMsgs.updateRecord())
          setTimeout(() => {
            navBack()
          }, 500)
        }
      },
    })

  const editData = (data: UnitPriceInput, status, selectedUnit) => {
    const unitIdList = selectedUnit?.map(x => x.unitId)
    if (unitIdList.length == 0 || unitIdList.length == null) {
      return setSnackBar('Please select at least one(1) unit to save')
    }

    editPriceAdjustment({
      variables: {
        input: {
          id: id,
          projectId: projectID,
          computationMode: data.computationMode.name,
          computationValue: parseFloat(data.computationValue.toString()),
          name: data.title,
          reason: data.reason.id,
          roundingMode: data.roundingMode.id,
          roundingPrecision: data.roundingPrecision.id,
          unitIdList: unitIdList,
          remark: data?.remark,
          status: status,
        },
      },
    })
  }
  return {
    editData,
    isLoadingEdit,
  }
}

export const useGetSelectUnitAtPriceAdjustment = () => {
  const { projectID }: any = useParams()
  const [
    getData,
    {
      data: { getSelectUnitAtPriceAdjustment: unitData } = {
        getSelectUnitAtPriceAdjustment: [],
      },
      loading: isFetchingUnits,
    },
  ] = useGetSelectUnitAtPriceAdjustmentLazyQuery({})

  const fetchSelectUnitList = id => {
    getData({
      variables: {
        projectId: projectID,
        id: id,
      },
    })
  }

  return {
    fetchSelectUnitList,
    unitData,
    isFetchingUnits,
  }
}

export const useUpdatePriceAdjustmentStatus = refetchData => {
  const [editPAstatus, { loading: editPAmoduleStatusLoading }] =
    useUpdatePriceAdjustmentStatusMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
    })

  const editStatus = (isApproved, projectId, sourceIds) => {
    editPAstatus({
      variables: {
        isApproved,
        projectId,
        sourceIds,
      },
      onCompleted: () => {
        setSnackBar(SystemMsgs.updateRecord())
        refetchData()
      },
    })
  }

  return { editStatus }
}

export const useUpdatePriceAdjustmentStatusById = () => {
  let navigate = useNavigate()
  const { navBack } = useNav()
  const { projectID }: any = useParams()
  const [editPAstatus, { loading: editPAmoduleStatusLoading }] =
    useUpdatePriceAdjustmentStatusMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        setSnackBar(SystemMsgs.updateRecord())
        setTimeout(() => {
          navBack()
        }, 500)
      },
    })

  const editStatus = (isApproved, projectId, sourceIds) => {
    editPAstatus({
      variables: {
        isApproved,
        projectId,
        sourceIds,
      },
    })
  }

  return { editStatus }
}

export const useGetReasonList = () => {
  const [
    fetchReasonList,
    {
      data: { getReasonList: reasonList } = {
        getReasonList: null,
      },
    },
  ] = useGetReasonListLazyQuery({})

  return {
    fetchReasonList,
    reasonList,
  }
}

export const useSoftDeletePriceAdjustment = refetchData => {
  const [softDeletePriceAdjustment, { loading: editPAmoduleStatusLoading }] =
    useSoftDeletePriceAdjustmentMutation({
      onError: error => {
        return error.graphQLErrors.map(({ message }) => {
          setSnackBar(message)
        })
      },
      onCompleted: data => {
        // hideDeleteDialog()
        setSnackBar(SystemMsgs.deleteRecord())
        refetchData()
      },
    })

  const softDeleteData = id => {
    softDeletePriceAdjustment({
      variables: {
        id,
      },
    })
  }

  return { softDeleteData }
}

export const useGetRounding = () => {
  const [
    fetchRounding,
    {
      data: { getRounding } = {
        getRounding: null,
      },
    },
  ] = useGetRoundingLazyQuery({
    onCompleted: data => {
      const { roundingMode, roundingPrecision } = data?.getRounding
      roundingModeListing = roundingMode
      roundingPrecisionListing = roundingPrecision
    },
  })
  return { fetchRounding, roundingModeListing, roundingPrecisionListing }
}
