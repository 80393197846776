import * as React from "react";

export const SalesCommissionIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#F44336"
      d="M12.563 13.125h-1.126c-.931 0-1.687.755-1.687 1.688v2.812c0 .932.755 1.688 1.688 1.688h1.124c.933 0 1.688-.756 1.688-1.688v-2.812c0-.933-.755-1.688-1.687-1.688"
    ></path>
    <path
      fill="#4CAF50"
      d="M18.188 9.188h-1.125c-.932 0-1.688.755-1.688 1.687v6.75c0 .932.756 1.688 1.688 1.688h1.125c.931 0 1.687-.756 1.687-1.688v-6.75c0-.932-.756-1.687-1.687-1.687"
    ></path>
    <path
      fill="#FFC107"
      d="M6.938 10.875H5.813c-.932 0-1.688.755-1.688 1.688v5.062c0 .932.756 1.688 1.688 1.688h1.125c.931 0 1.687-.756 1.687-1.688v-5.062c0-.933-.756-1.688-1.687-1.688"
    ></path>
    <path
      fill="#424242"
      d="M12 10.875a.6.6 0 0 1-.208-.04l-5.625-2.25a.563.563 0 0 1 .416-1.046l5.321 2.127 5.372-4.292a.563.563 0 0 1 .703.877l-5.625 4.5a.56.56 0 0 1-.354.124"
    ></path>
    <path
      fill="#4CAF50"
      d="M17.625 6.938a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25M12 11.438a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25M6.375 9.188a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25"
    ></path>
  </svg>
);