import { AddBoxOutlined, IndeterminateCheckBoxOutlined as IndeterminateCheckBoxIcon} from '@mui/icons-material';
import TreeItem, { useTreeItem, TreeItemContentProps, treeItemClasses} from '@mui/lab/TreeItem';
import AppContext, { AppContextProps } from 'containers/context/Context';
import { IconButton, TextField, Typography, Box } from '@mui/material';
import {CommissionItemList, tabs} from "./CommissionCalculationListing.json";
import { DatePickerX } from 'components/Inputs/DatePickerX';
import {
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
    House as HomeIcon,
  } from '@mui/icons-material';
import { useState, useEffect, useContext } from "react";
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useNav from 'components/RouteService/useNav';
import TreeView from '@mui/lab/TreeView';
import { 
    FooterButtonContainer, 
    FooterFixedRoot 
} from '@ifca-ui/core/src/components/Footer/Footer';
import { CommonYupValidation } from 'helpers/yup';
import * as yup from 'yup';
import { 
    SubContainerX, 
    useMenuOption, 
    ListItemText, 
    FooterButton, 
    FloatButton, 
    Container, 
    ListItem, 
    MenuItem,
    Card, 
    Tabs,
    Tab
} from "@ifca-ui/core";
interface CreateCommissionProps {
    selectDate: string
    id: number
}

export const CommissionCalculationCreate = () => {
    const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
    const teamAssignmentSchema = yup.object().shape({
        selectDate: CommonYupValidation.requireField(SystemMsgs.date()),
    })
    const [generated, setGenerated] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const { navBack} = useNav()
    const today  = new Date()
    const {
        handleSubmit,
        register,
        control,
        setValue,
        watch,
        reset,
        clearErrors,
        formState: { errors, isSubmitted },
      } = useForm<CreateCommissionProps>({
        defaultValues: {
          id: null,
          selectDate: `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`,
        },
        mode: 'onSubmit',
        resolver: yupResolver(teamAssignmentSchema),
      })
    useEffect(()=>{
        rootDispatch({
            type: 'headerComponent',
            payload: {
                ...rootState.headerComponent,
                leftIcon: {
                    icon: 'close',
                    props: {
                        onClick: () => navBack(),
                    },
                },
                bottomSection: {
                    breadcrumbs: {
                        current: `Commission Calculation`,
                        maxItems: 1,
                    },
                },
                rightIcon: {}
            }
        })
    },[])
    const [expanded, setExpanded] = useState<string[]>([]);
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleExpandClick = (event: React.MouseEvent, nodeId: string) => {
        event.stopPropagation();
        setExpanded((prevExpanded) =>
            prevExpanded.includes(nodeId)
            ? prevExpanded.filter((id) => id !== nodeId)
            : [...prevExpanded, nodeId] 
        );
    };
    return (
    <>
        <SubContainerX>
            <Box display="flex">
                <Box width={'100%'}>
                    <Tabs
                        className="bill-item-tabs"
                        value={tabIndex}
                        onChange={(e, v) => setTabIndex(v)}
                        variant="fullWidth"
                        centered
                        style={{ width: '100%' }}
                    >
                        {tabs.map((tab, i) => (
                            <Tab
                                key={i}
                                value={i}
                                style={ { flexGrow: 1, maxWidth: '100%'}}
                                label={
                                <Typography className="text-mdLabel">{tab.label}</Typography>
                                }
                            />
                        ))}    
                    </Tabs>
                </Box>
            </Box>
        </SubContainerX>
        <Container>
            <Card >
                <Box sx={{width:1}}>
                    <form id='create-form' >
                        <Typography>Select Date</Typography>
                        <Box sx={{display:"flex", justifyContent:"space-between", gap:"10px"}}>
                            <Controller 
                                control={control}
                                name='selectDate'
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <>
                                    <DatePickerX
                                        control={control}
                                        name="selectDate"
                                        label="Select Date"
                                        required
                                        helperText={errors?.selectDate?.message}
                                        error={!!errors?.selectDate}
                                    />
                                    </>
                                )}
                            />
                            <FooterButton color='primary' variant="contained" sx={{
                                margin: '27px 18px 13px 9px',
                                alignItems:"bottom",
                                padding:"10px 100px"
                            }}
                            onClick={()=>{setGenerated(!generated)}}>
                                Generate
                            </FooterButton>    
                        </Box>
                    </form>
                </Box>
            </Card>                        
        </Container>
        <Container>

        <Box display={generated?"block":"none"}>
            <TreeView
                expanded={expanded}
                onNodeToggle={handleToggle}
                defaultCollapseIcon={<IndeterminateCheckBoxIcon />}
                defaultExpandIcon={<AddBoxOutlined />}
                sx={{
                    background: 'white',
                    marginTop: 1,
                    position: 'relative',
                    padding: '16px 16px 16px 16px',
                    borderRadius: '4px',
                    boxShadow: `
                    0 3px 1px -2px rgba(0, 0, 0, 0),
                    0 1px 10px 2px rgba(0, 0, 0, 0.05),
                    0 0px 5px 0 rgba(0, 0, 0, 0.1)
                    `
                    ,
                    [`& .${treeItemClasses.group}`]: {
                      marginLeft: '14.5px',
                      paddingLeft: '12px',
                      borderLeft: '1px dashed black',
                    },
                    [`& .${treeItemClasses.content}`]: {
                      marginBottom: '12px',
                    },
                    [`& .${treeItemClasses.label}`]: {
                      paddingLeft: '8px',
                    },
                    // [`& .${treeItemClasses.selected}`]: {
                    //   background: 'inherit !important', 
                    //   color: 'inherit !important', 
                    // },
                    // [`& .${treeItemClasses.focused}`]: {
                    //   background: 'inherit !important',
                    // },
                    "& .MuiTreeItem-group": { marginLeft: "14.5px", paddingLeft: "12px", borderLeft: "1px dashed black" }
                  }}
            >
                {CommissionItemList.map((node, nodeIndex) => (
                    <TreeItem 
                        key={node.id}
                        label={
                            <Box 
                                sx={{display: "flex", justifyContent: "space-between"}}
                                onClick={(e) => handleExpandClick(e, node.id)}
                            >
                            <Box
                                sx={{
                                padding: "4px 8px",
                                display: "block",
                                justifyContent:"space-between",
                                paddingTop: 1, 
                                paddingBottom: 1,
                                cursor: "pointer",
                                // "&:hover": { backgroundColor: "lightgray" },
                                }}
                            >
                                <Typography className='text-xsTitle'>{node.name}</Typography>
                                <Typography className='text-label'>{node.description}</Typography>
                            </Box>
                            <Box sx={{
                                padding: "4px 8px",
                                display: "flex",
                                justifyContent:"space-between",
                                paddingTop: 1, 
                                paddingBottom: 1,
                                cursor: "pointer",
                                }}>
                                <Typography className='text-label'>{node.label}</Typography>
                                <IconButton onClick={e => {}}>
                                    {expanded.includes(node.id)?
                                    <ExpandLessIcon style={{ color: '#212121' }}/>:
                                    <ExpandMoreIcon style={{ color: '#212121' }}/>
                                    }
                                </IconButton>
                            </Box>
                            </Box>
                        } 
                        nodeId={node.id}
                        >
                        {node.children? node.children.map((child, childIndex) => (
                        <TreeItem
                            key={child.id}
                            nodeId={String(child.id)}
                            label={
                                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                <Typography className='text-xsTitle' 
                                    sx={{paddingTop: 2, paddingBottom: 2}}>
                                    {child.label}
                                </Typography>
                                <IconButton onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    // setModeType('')
                                    handleExpandClick(e, child.id)
                                    // setValue('name', child?.label)
                                    }}>
                                    {expanded.includes(child.id)?
                                        <ExpandLessIcon style={{ color: '#212121' }}/>:
                                        <ExpandMoreIcon style={{ color: '#212121' }}/>
                                    }          
                                </IconButton>
                                </Box>
                            }
                            sx={{
                                "&.Mui-focused": {
                                backgroundColor: "transparent !important",
                                },
                                "& .MuiTreeItem-content.Mui-focused": {
                                backgroundColor: "transparent !important",
                                },
                                "& .MuiTreeItem-content.Mui-selected": {
                                backgroundColor: "transparent !important",
                                },
                                "& .MuiTreeItem-content:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04) !important",
                                },
                            }}
                        >
                            {child.children?.map((subChild, subChildIndex) => (
                            <TreeItem key={subChildIndex} nodeId={subChild.id} 
                                label={
                                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                        <Typography className='text-xsTitle'
                                        sx={{paddingTop: 2, paddingBottom: 2}}
                                        >
                                        {subChild.label}
                                        </Typography>
                                        <IconButton 
                                            onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            // setModeType('tiered')
                                            handleExpandClick(e, subChild.id)
                                            // setValue('name', subChild?.label)
                                            }}
                                        >
                                        {expanded.includes(subChild.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}         
                                        </IconButton>
                                    </Box>
                                }
                                sx={{[`& .${treeItemClasses.group}`]: {
                                    marginLeft: '14.5px !important',
                                    paddingLeft: '12px !important',
                                    borderLeft: '0px !important',
                                }}}
                            >
                                {subChild?.DataFees?.map((data, index) => (
                                    <TreeItem nodeId={data.id} key={index} onClick={(e)=>handleExpandClick(e, data.id)}
                                        label={
                                            <>
                                            <Box sx={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center", pr:'9px'}} key={index}>
                                            <Box>
                                                <Box sx={{mt: 2}}>
                                                <Typography key={index} variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }} className="text-label">
                                                    • <HomeIcon/>{data.description} to {data.targetRangeh}
                                                </Typography>
                                                </Box>
                                                {data?.children?.map((grandChildren, grandChildrenIndex)=>(
                                                    <>
                                                    <TreeItem key={grandChildrenIndex} nodeId={grandChildren.id} 
                                                    label={
                                                        <Typography className='text-label'>{grandChildren.description}</Typography>
                                                    }/>
                                                    </>
                                                ))}
                                            </Box>
                                            <IconButton onClick={e => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                // setModeType('')
                                                handleExpandClick(e, data.id)
                                                // setValue('name', `Target range from ${data?.targetRangel} to ${data?.targetRangeh}`)
                                                }}>
                                                {expanded.includes(data.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}       
                                            </IconButton>
                                            </Box>
                                            </>
                                        }
                                    >
                                    </TreeItem>
                                ))}
                            </TreeItem>
                            ))}
                        </TreeItem>  
                        )):<></>}
                    </TreeItem> 
                ))}
            </TreeView>
        </Box>
        </Container>
        <FooterFixedRoot width={1} display={"flex"} justifyContent={"center"} gap={'10%'}>
            <FooterButtonContainer>
                <FooterButton 
                color='primary' 
                variant="contained" 
                sx={{
                    margin: '27px 18px 13px 9px',
                    alignItems:"bottom",
                    padding:"10px 100px"
                }}
                onClick={()=>navBack()}>
                    Cancel
                </FooterButton>
            </FooterButtonContainer>
            <FooterButtonContainer>
                <FooterButton 
                color='primary' 
                variant="contained" 
                sx={{
                    margin: '27px 18px 13px 9px',
                    alignItems:"bottom",
                    padding:"10px 100px"
                }}
                onClick={()=>navBack()}>
                    Confirm
                </FooterButton>
            </FooterButtonContainer>
        </FooterFixedRoot>
    </>)
}