import projectCommissionTeamStructureRoutes from './ProjectCommissionTeamStructure/Routes';
import CompanyCommissionTeamStructureRoutes from './CompanyCommissionTeamStructure/Routes';
import ProjCommPkgTargetRoutes from "./ProjectCommissionPackageTarget/Routes";
import CommissionCalculationRoutes from "./CommissionCalculation/Routes";
import { RouteObject } from "react-router";
import SalesComissionSubMenu from "./SalesComissionSubMenu";
import CommissionTypeRoutes from "./CommissionType/Routes";
import ProjectCommissionRoutes from "./ProjectCommission/Routes";
import EmployeeJobPositionRoutes from "./EmployeeJobPosition/Routes";
import CompCommPkgTargetRoutes from "./CompanyCommissionPackageTarget/Routes";
import CompanyRewardRoutes from "./CompanyReward/Routes";

export const SalesComissionRoutes: RouteObject[] = [
    {
        path: '',
        element: <SalesComissionSubMenu />,
    },
    ...projectCommissionTeamStructureRoutes,
    ...CompanyCommissionTeamStructureRoutes,
    ...CommissionCalculationRoutes,
    ...CommissionTypeRoutes,
    ...ProjectCommissionRoutes,
    ...ProjCommPkgTargetRoutes,
    ...EmployeeJobPositionRoutes,
    ...CompCommPkgTargetRoutes,
    ...CompanyRewardRoutes
]