import {
  Container,
  EmptyState,
  FloatButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  SearchBar,
  SubContainerX,
  useMenuOption,
} from '@ifca-ui/core'
import { Box, IconButton, Typography } from '@mui/material'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { useContext, useEffect, useState } from 'react'
import { Add, MoreVert } from '@mui/icons-material'
import useNav from 'components/RouteService/useNav'
import CommissionTypeDetailDialog from './CommissionTypeDetailDialog'
import { baseSalesCommissionPath } from '../SalesComissionSubMenu'
import { useLocation, useParams } from 'react-router'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { CommissionTypeEntity, useDeleteCommissionTypeMutation, useGetCommissionTypeListingLazyQuery } from 'generated/graphql'
import { setSnackBar } from 'helpers/snackBarSubjectHelper'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'

const CommissionTypeListing = () => {
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const { projectID } = useParams()
  const { state } = useLocation()
  const [searchInput, setSearchInput] = useState<string>('')
  const { filteredList, handleSearch, setOriginalListing } =
    useFuseSearch();
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const open = Boolean(anchorEl)
  const { navBack, navTo } = useNav()
  const [showDialog, setShowDialog] = useState<boolean>(false)

  // QUERY
  const [getCommissionTypeListing] = useGetCommissionTypeListingLazyQuery({
    onCompleted(data) {
      setOriginalListing(data?.getCommissionTypeListing ?? [])
    },
  })

  const [deleteCommissionType] = useDeleteCommissionTypeMutation({
    onCompleted(data) {
      if(data.deleteCommissionType){
        setSnackBar(SystemMsgs.deleteRecord())
        getCommissionTypeListing()
      }
    },
  })

  // USE EFFECT
  useEffect(() => {
    rootDispatch({
      type: 'headerComponent',
      payload: {
        ...rootState.headerComponent,
        leftIcon: {
          icon: 'back',
          props: {
            onClick: () => navBack(),
          },
        },
        rightIcon: {},
        topSection: {
          smTitle: 'Sales Commission',
          title: state?.projectName ?? localStorage.getItem('projectName'),
        },
        bottomSection: {
          breadcrumbs: {
            maxItems: 2,
            current: 'Commission Type',
          },
        },
      },
    })
  }, [projectID])

  useEffect(() => {
    getCommissionTypeListing()
  }, [])

  return (
    <>
      <CommissionTypeDetailDialog
        showDialog={showDialog}
        data={menu?.data}
        onCloseDialog={() => setShowDialog(false)}
      />
      <SubContainerX>
        <SearchBar
          title={
            <Typography
              component="span"
              className="text-mdLabel"
              variant="inherit"
            >
              Commission Type Listing (
              <Typography
                component="span"
                variant="inherit"
                className="search-bar-count-f"
              >
                {filteredList?.length ?? 0}
              </Typography>
              )
            </Typography>
          }
          searchInput={{
            value: searchInput,
            onChange: e => {
              setSearchInput(e.target.value)
              handleSearch(e.target.value, ['name', 'payOutType', 'targetBy'])
            },
            onChangeClearInput: () => {
              setSearchInput('')
              handleSearch('', ['name', 'payOutType', 'targetBy'])
            },
          }}
        />
      </SubContainerX>
      <Container>
        <List>
          {!filteredList.length ? (
            <EmptyState
              title="No Record Found"
              subTitle="Click add button to insert new records"
            />
          ) : (
            filteredList.map((list: CommissionTypeEntity, index) => (
              <ListItem
                key={list.id}
                secondaryAction={
                  <IconButton
                    onClick={e => handleClick(e, list.id, index, list)}
                  >
                    <MoreVert />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginBottom="3px"
                    >
                      <Typography className="text-smTitle">
                        {list.name}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box component="span">
                      <Typography
                        component="span"
                        className="text-desc"
                        display="block"
                      >
                        <Typography component="p" className="text-desc">
                          Calc. Type:{' '}
                          <Typography
                            component="span"
                            color="primary"
                            className="text-xsTitle"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {list.payOutType?.toLowerCase()}
                          </Typography>
                        </Typography>
                        <Typography component="p" className="text-desc">
                          Target By:{' '}
                          <Typography
                            component="span"
                            color="primary"
                            className="text-xsTitle"
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {list.targetBy?.toLowerCase()}
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography component="p" className="text-desc">
                        Calc. Interval In Month:{' '}
                        <Typography
                          component="span"
                          color="primary"
                          className="text-xsTitle"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {list.interval}
                        </Typography>
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Container>
      
      <FloatButton
        color="primary"
        onClick={() => {
          navTo(`${baseSalesCommissionPath}/${projectID}/commission-type/add`, {
            projectName: state?.projectName ?? localStorage.getItem('projectName')
          })
        }}
      >
        <Add />
      </FloatButton>

      {/* MENU */}
      <Menu
        id="menu-commission-item"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={()=> setShowDialog(true)}>View</MenuItem>
        <MenuItem
          onClick={() => {
            navTo(`${baseSalesCommissionPath}/${projectID}/commission-type/edit/${menu?.id}`, {
              ...menu?.data
            })
          }}
        >
          Edit
        </MenuItem>
        <MenuItem 
          onClick={(e)=> {
            deleteCommissionType({variables: {id: menu?.id}})
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default CommissionTypeListing
